.eventoCreadoSection {
    text-align: center;
    max-width: 900px;
    background: hsla(0, 0%, 0%, 0);
    padding: 20px;
    margin: 30px auto;
    min-height: 70vh;

    img{
      margin: 0 auto 8px;
    }
  }

  .mpVinculadoSeccion img{
    width: 100px;
  }
  
  .mpVinculadoSeccion h2{
    color: #009ee3;
    font-weight: 700;
  }

  .evento-img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .fondoDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    background-color: rgba(204, 204, 204, 0.4745098039);
    border-radius: 15px;
    margin-bottom: 8px;
  }
  .contact-info {
    font-size: 18px;
    margin-top: 20px;
    color: #333333;
    display: flex;
    align-items: center;
  }

  