.bodyButacas{
    width: 90%;
    margin: 20px auto;
    box-shadow: 1px 0 10px #bbbbbb;
    border-radius: 10px;
    max-width: 800px;
    background: #ffffff;
    padding-bottom: 0.1px;

    .header{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        border-bottom: 2px solid #99419d;
        padding: 16px;

        h4{
            padding: 0 !important;
            font-size: 16px;
            text-align: left;
            font-weight: 600;
        }

        p{
            font-size: 12px !important;
            font-weight: 400 !important;
        }

    }
}