//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: 70vh;
  padding: 40px 20px !important;
  background-color: #f7f7f7;
}

.panelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 70vh;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
}

.headerRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.title {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 32px;
  }
}

.eventoTitle {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 16px;

  span {
    font-weight: 400;
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.contenedortabla {
  width: 100%;
  overflow-x: auto;
  max-width: 1200px;
}

.instruccion {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;

    @media (max-width: 767px) {
      padding: 4px;
      font-size: 12px;
    }
  }

  th {
    background-color: $violetaOscuro;
    color: #fff;
    font-weight: 500;
    font-size: 14px;

    @media (max-width: 767px) {
      font-size: 12px;
      
      // Ocultar columnas en móvil
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  td {
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ccc !important;

    @media (max-width: 767px) {
      padding: 6px;
      font-size: 12px;
      
      // Ocultar columnas en móvil
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.verTicketsButton {
  background-color: $violeta;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s;
  font-family: $tipografia;
  font-size: 14px;
  min-width: 100px !important;
  white-space: nowrap;
  margin: 8px auto;

  &:hover {
    background-color: $violetaOscuro;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    background-color: $violeta;
    color: white;
    border: none;
    padding: 10px 20px;
    font-family: $tipografia;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s;
    margin: 0 5px;

    &:hover {
      background-color: $violetaOscuro;
      transform: scale(1.05);
    }

    &:disabled {
      background-color: $colorDeFuente2;
      cursor: not-allowed;
      transform: none;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  span {
    font-family: $tipografia;
    font-size: 14px;
    color: $colorDeFuente;
    margin: 0 10px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}
.optionsContainer {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  
  .moreButton {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: $colorDeFuente;
    
  }

  .dropdownMenu {
    position: absolute;
    top: -100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    min-width: 120px;

    a {
      padding: 10px;
      text-decoration: none;
      color: $violetaOscuro;
      font-size: 14px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
