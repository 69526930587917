//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0;
  padding-bottom: 60px;

  p {
    color: #666;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.contenedorIntro{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: url(https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Banners+de+home/fondo-crear-evento.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .tituloContenedor {
    width: 90%;
    text-align: left;
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    height: 100%;
  
    h1 {
      text-align: left;
      color: #fff;
      padding: 0;
      font-weight: 700;
      font-size: 32px;
      margin-top: 20px;
    }
  
    h3 {
      font-size: 16px;
      text-align: left;
      margin: 20px 0 0px;
      font-weight: 400;
    }
  
    h4{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      font-weight: 700;
    }
  
    @media (min-width: 768px) {
      width: 45%;
      padding-top: 0;
  
      h1 {
        margin-top: 20px;
        font-size: 34px;
      }

      h3{
        font-size: 18px;
      }
      h4{
        font-size: 20px;
      }
    }

    @media (min-width: 990px) {
      width: 40%;
      padding-right: 30px;
      h1{
        margin-top: 0;
      }
    }
  }
  
  .videoContenedor {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;
  
    img {
      width: 90%;
      margin-top: 0;
    }
  
    @media (min-width: 768px) {
      width: 45%;
      padding: 0;
      margin-top: 0;
    }

    @media (min-width: 990px) {
      width: 40%;
    }
  }

  @media (min-width: 768px) {
    padding: 40px 2.5%;
  }

  @media (min-width: 990px) {
    padding: 70px 5%;
    
  }

}

.contenedorCaracteristicas {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  max-width: 1000px;

  .caracteristicaItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    max-width: 320px;
    padding: 8px;
  
    .icono {
      width: 50px;
      font-size: 48px;
      margin-bottom: 10px;
      color: #99419D;
    }

    .contTexto{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 8px;

      h3{
        font-size: 18px;
        text-align: left;
        font-weight: 700;
      }
      p{
        font-size: 14px;
        text-align: left;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
    
    @media (min-width: 768px) {
      margin-bottom: 0;
      padding: 30px 0;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 60px 20px;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.demostracionPanel{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .tituloPlataforma{
    font-size: 24px;
    font-weight: 700;
    width: 90%;
    margin: 0 auto 16px;

    @media (min-width:768px) {
      font-size: 32px;
    }
  }

  p{
    font-size: 12px;
    width: 80%;
    text-align: center;
    max-width: 780px;
    margin-bottom: 8px;

    @media (min-width:768px) {
      font-size: 14px;
    }
  }

  .imagenConfiguracionEvento{
    width: 80%;
    align-self: center;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 0 20px #cccccca3;
  }
}



.seccionBotones {
  margin-top: 30px;

  h3 {
    font-size: 18px;
  }
}

.cardContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;

  h3 {
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    z-index: 100;

    @media (min-width:768px) {
      font-size: 32px;
    }
  }

  .card {
    position: relative;
    width: 280px;
    max-width: 280px !important;
    height: 470px;
    border-radius: 14px;
    z-index: 11;
    padding: 0 10%;
    overflow: hidden;
    display: flex;
    border: none !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    
    .bg {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 1000!important;
      padding: 0 10%;
      width: 96.5%;
      border: none !important;
      height: 460px;
      z-index: 2;
      background: rgba(255, 255, 255, .95);
      backdrop-filter: blur(24px);
      border-radius: 10px;
      overflow: hidden;
      outline: 2px solid white;
    }
    
    .blob {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 260px;
      height: 400px;
      border: none;
      border-radius: 50%;
      background-color: rgba(238, 130, 238, 0.493);
      opacity: 1;
      filter: blur(20px);
      animation: blob-bounce 5s infinite ease;
    }
  
    .blob2 {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 260px;
      height: 400px;
      border: none;
      border-radius: 50%;
      background-color: #98419db7;
      opacity: 1;
      filter: blur(20px);
      animation: blob-bounce 5s infinite ease;
    }
  
    .blob3 {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 260px;
      height: 400px;
      border: none;
      border-radius: 50%;
      background-color: #862f8baf;
      opacity: 1;
      filter: blur(20px);
      animation: blob-bounce 5s infinite ease;
    }

    .contH2{
      display: flex;
      width: 100%;
      margin: 16px 0 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h2{
      font-size: 12px;
      font-weight: 500;
      border-radius: 50px;
      padding: 8px 12px;
      border: solid 2px #ccc;
      margin-bottom: 0;
    }

    h3{
      font-size: 24px;
      font-weight: 600;
      color: #252525;
      margin-bottom: 0;

    }

    p{
      font-size: 12px;
      margin-bottom: 15px;
      text-align: left;
    }

    .lineaDivisora {
      border-top: 1px solid #cdcdcd;
      box-sizing: border-box;
      width: 100%;
      height: 1px;
      margin: 15px auto 20px;
    }
    .contenedorBeneficios{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .beneficio{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .contImg{
        display: flex;
        height: 100%;
        max-width: 15px;
        flex-direction: column;
        align-items: center;
      }
      img{
        width: 15px;
        height: auto;
        height: 100%;
        margin-right: 16px;
      }

      p{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-weight: 500;
        width: 100%;
        text-align: left;
        font-size: 12px;
      }
    }

    .crearEventoBoton {
      background-color: #862f8b;
      min-height: 40px;
      max-height: 40px;
      min-width: 116px;
      color: white;
      border: none;
      bottom: 0;
      position: absolute;
      bottom: 8px;
      right:  5%;
      margin: 8px auto;
      width: 90%;
      border-radius: 5px;
      padding: 8px 16px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      transition: background-color 0.1s;
      display: flex;               
      align-items: center;         
      justify-content: space-between;
      
     
      .arrowIcon {                 
        font-size: 20px;           
        margin-left: 8px;          
      }
    }
    .wsp {
      background-color: rgba(259, 65, 157, 0.08);
      color: #99419D; 
      padding: 10px 20px;
      cursor: pointer;
      text-align: center; 
      text-decoration: none; 
    
      &:hover {
        background-color: #9b5c9e; 
        color: white;
      }
    }
    
  }
  
  
  
  @keyframes blob-bounce {
    0% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  
    25% {
      transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }
  
    50% {
      transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }
  
    75% {
      transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }
  
    100% {
      transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
  }
  
  .cardd {
    width: 280px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    background-color: #fff;
    transition: transform 0.3s ease;
    min-height: 400px;

    &:hover {
      transform: translateY(-5px);
    }

    h2 {
      color: #333;
      margin-bottom: 1rem;
    }

    p {
      color: $colorDeFuente;
      text-align: left;

      &.price {
        color: #333;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
      }
    }

    .crearEventoBoton {
      background-color: #862f8b;
      min-height: 40px;
      min-width: 116px;
      color: white;
      border: none;
      position: absolute;
      bottom: 0;
      left: 5%;
      right: 5%;
      margin: 0 auto;
      width: 90%;
      border-radius: 10px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      transition: background-color 0.3s;
      
    }
  }
}