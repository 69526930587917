.bodyCambiarContraseña {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh; 
}

.registroForm {
  width: 90%;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(238, 238, 238, 0.1);
  border-radius: 15px;
  box-shadow: 1px 0 20px #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form {
    width: 100%;
  }

  h2 {
    color: #1b1b1b;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .formGroup {
    width: 100%;
    margin-bottom: 10px;
    position: relative; 

    label {
      font-size: 14px;
      color: #666;
      margin-bottom: 5px;
      display: block;
    }

    .input {
      height: 40px;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      border: 1px solid #ccc;
      font-size: 14px;
      padding-right: 40px; // Espacio para el icono
    }

    .togglePassword {
      position: absolute;
      top: 46px;
      right: 10px;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      color: #666;

      @media(min-width: 768px){
        right: 15px;
      }
    }

    .togglePassword:focus {
      outline: none;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
