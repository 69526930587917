.skeletonProducto {
    display: flex;
    flex-direction: column;
    max-width: 150px;
    width: 35%;
    margin: 16px;
    border-radius: 8px;
    background-color: #fff;

    .skeletonImage {
        width: 100%;
        height: 150px; 
        border-radius: 6px 6px 0 0;
    }

    .skeletonDatos {
        display: flex;
        flex-direction: column;
        padding: 8px;
        
        .skeletonTitle {
            width: 80%;
            height: 20px;
            margin-bottom: 8px;
        }

        .skeletonPrice {
            width: 50%;
            height: 20px;
        }
    }

    // Estilos generales para el esqueleto
    .skeleton {
        background-color: #e0e0e0;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -150%;
            height: 100%;
            width: 100%;
            background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
            animation: loading 1.5s infinite;
        }
    }
}

@keyframes loading {
    0% {
        left: -150%;
    }
    50% {
        left: 0;
    }
    100% {
        left: 150%;
    }
}