//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 70vh;

    .titulo {
        font-weight: 800;
        line-height: 1.2;
        font-size: 24px;
        color: $colorDeFuente;
        padding-left: 10px;
        width: 90%;
        margin: 16px auto 8px;
        max-width: 900px;
    }

    .subtitulo {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 4px;
        color: $colorDeFuente;
        width: 90%;
        padding-left: 10px;
        max-width: 900px;

        span {
            font-weight: 400;
        }
    }

    .error {
        color: rgb(255, 92, 93);
        text-align: left;
        font-size: 14px !important;
        padding: 0 0px 8px 0 !important;
        width: 100%;
        margin: -10px auto 0;
    }

    .fondoSub {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 12px;
        background-color: #e6e6e6;
        border-radius: 15px;
        width: 90%;
        max-width: 900px;
        margin-bottom: 16px;

        p {
            font-size: 14px;
            text-align: left;
            font-weight: 400;
            color: $colorDeFuente2;
        }
    }

    .label {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        text-align: left;
        color: $colorDeFuente;
        margin-bottom: 8px;
        border-bottom: solid 1px #ccc;
    }

    .contenedor {
        width: 90%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        .imagenActual {
            width: 100%;

            .contImgActual {
                width: 100%;

                span {
                    font-size: 14px;
                    font-weight: 600;
                }

                p {
                    margin-top: 16px;
                }
            }

            .instructivoImagen {
                width: 100%;
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                margin: 8px auto 0;
            }
        }

        .submitButton {
            border: solid 1px #1b1b1b;
            border-radius: 10px;
            width: 200px;
            height: 37px;
        }

        input[type="file"] {
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 8px;
            margin-bottom: 16px;
        }

        textarea {
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 8px;
            font-size: 14px;
            margin-bottom: 16px;
            resize: none;
        }
    }

    .mesas {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        max-width: 900px;

        .mesa {
            width: 100%;
            border: solid 1px #ccc;
            border-radius: 10px;
            margin-bottom: 10px;
            padding: 16px;
            position: relative;

            label {
                font-size: 16px;
                font-weight: 500;
                color: $colorDeFuente;
            }

            input {
                height: 42px;
                width: 100%;
                border-radius: 8px;
                padding: 10px;
                margin-bottom: 10px;
                border: 1px solid #ccc;
                font-size: 14px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
                -moz-appearance: textfield;
            }

            .checkboxApple {
                position: relative;
                width: 50px !important;
                height: 25px;
                margin: 10px 20px 20px 4px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                input[type="checkbox"] {
                    display: none;
                }
            }

            .labelToggle {
                position: absolute;
                top: 0;
                left: 0;
                width: 50px !important;
                height: 25px;
                border-radius: 50px;
                background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
                cursor: pointer;
                transition: all 0.3s ease;
            }

            .labelToggle:after {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
                transition: all 0.3s ease;
            }

            .checkboxApple input[type="checkbox"]:checked+label {
                background: linear-gradient(to bottom, #4cd964, #5de24e);
            }

            .checkboxApple input[type="checkbox"]:checked+label:after {
                transform: translateX(25px);
            }

            .checkboxApple label:hover {
                background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
            }

            .checkboxApple label:hover:after {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            }

            .eliminarBtn {
                font-size: 12px;
                border: solid 1px rgb(255, 92, 93);
                font-weight: 500;
                align-self: center !important;
                cursor: pointer;
                outline: none;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 16px;
                border-radius: 8px;
                margin: 10px auto;
                background-color: rgba(0, 0, 0, 0);
                color: rgb(255, 92, 93);

                &:hover {
                    background-color: rgb(255, 92, 93);
                    color: white;
                }

                svg {
                    margin-left: 4px;
                }
            }

            .numeroMesa {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                font-size: 20px;
                padding: 4px;
                font-weight: 600;
                color: $violeta;

                span {
                    font-weight: 400;
                }
            }
        }
    }

    .contBtnGuardar {
        width: 90%;
        max-width: 900px;
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .submitButton {
            font-size: 14px;
            border: solid 1px $violeta;
            font-weight: 500;
            cursor: pointer;
            outline: none;
            transition: all 0.3s ease;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            min-width: 250px;
            border-radius: 8px;
            margin: 10px;
            background-color: rgba(0, 0, 0, 0);
            color: $violeta;

            &:hover {
                background-color: $violeta;
                color: white;
            }

            svg {
                margin-left: 4px;
            }
        }
    }

    .agregarBtn {
        font-size: 14px;
        border: solid 1px $rosa;
        font-weight: 500;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        min-width: 250px;
        border-radius: 8px;
        margin: 10px;
        background-color: rgba(0, 0, 0, 0);
        color: $rosa;

        &:hover {
            background-color: $rosa;
            color: white;
        }

        svg {
            margin-left: 4px;
        }
    }

    @media(min-width: 768px) {
        .titulo {
            font-size: 30px;
        }

        .subtitulo {
            font-size: 20px;
        }

        .fondoSub {
            p {
                font-size: 16px;
            }
        }

        .label {
            font-size: 18px;
        }

        .contImgActual {
            span {
                font-size: 16px;
            }
        }
    }
}

.exampleButton {
    padding: 8px;
    border-radius: 10px;
    border: solid 1px #ccc;
    font-size: 14px;
    margin-bottom: 15px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    position: relative;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.exampleImage {
    width: 100%;
    border-radius: 10px;
}