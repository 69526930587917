/* LocationSearchInput.module.scss */
/* LocationSearchInput.module.scss */
.locationSearchInput {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &::placeholder {
    color: #999;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.locationInput {
  width: 100%;
  padding-right: 40px; // Adjust padding to prevent text from overlapping the icon
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.googleMapsIcon {
  position: absolute;
  right: 10px; 
  top: 14px;
  height: 16px; // Adjust size as needed
  width: auto;
}

.pac-container {
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.pac-item {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.pac-item:hover {
  background-color: #fafafa;
}

.pac-item-query {
  font-weight: bold;
}

.pac-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
