//Colores de la empresa
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.pagoCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 90%;
  max-width: 800px;
  margin: 30px auto;
  box-shadow: 1px 0 20px #bbbbbb;
  border-radius: 15px;

  .icono {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 10%;
    height: auto;
    min-height: 100%;
    border-right: solid 1px $violeta;
    
    img {
      width: 100%;
      padding: 5px;
      display: none;
    }
  }

  .contInfo{
    width: 90%;
    padding: 10px;

    .fondoSub{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        padding: 10px;
        background-color: #cccccc46;
        border-radius: 10px;
        margin-bottom: 8px;

        h2{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 0;
            font-size: 18px;
            font-weight: 600;
            color: #1b1b1b;

            img{
                width: 20px;
                margin-top: -3px;
                margin-right: 3px;
            }
        }
    }

    p{
        margin: 0 10px 8px;
        font-size: 14px;
        border-bottom: solid 1px #ccc;
        padding-bottom: 2px;
    }
    
  }

  @media(min-width: 768px){


    .icono{
    
        img{
            display: block;
        }
    }

    .contInfo{
        .fondoSub{
            h2{
                img{
                    display: none;
                }
            }
        
        }
        
    }
}
}
