.mission-container {
    width: 100%;
    height: auto;
    padding: 50px 20px; 
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
  
    .mission-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
  
      .mission-title {
        text-align: center;
        color: #1D1D1D;
        font-size: 24px; 
        font-weight: 600;
        line-height: 36px;
        word-wrap: break-word;
      }
  
      .mission-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
  
        .mission-quote {
          color: #99419D;
          font-size: 28px; 
          font-style: italic;
          font-weight: 700;
          line-height: 32px;
          word-wrap: break-word;
          text-align: left;
        }
  
        .mission-text {
          color: #101010;
          font-size: 16px; 
          font-weight: 500;
          line-height: 24px;
          word-wrap: break-word;
          text-align: left;
        }
      }
    }
  
    .help-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      width: 100%;
  
      .help-title {
        text-align: center;
        color: #1D1D1D;
        font-size: 24px; 
        font-weight: 600;
        line-height: 36px;
        word-wrap: break-word;
      }
  
      .help-list {
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: stretch; 
        width: 100%;

        .icon{
          color: #99419D;
        }
  
        .help-item {
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 12px; 
  
          .icon-wrapper {
            position: relative;
            width: 40px;
            height: 40px;
  
            .circle-icon {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #f0f0f0;
            }
  
            .inner-icon {
              width: 70%;
              height: 70%;
              background-color: #99419D;
              border-radius: 50%;
              position: absolute;
              top: 15%;
              left: 15%;
            }
          }
  
          .help-item-title {
            color: #0C111A;
            font-size: 18px; 
            font-weight: 600;
            word-wrap: break-word;
          }
  
          .help-item-description {
            color: #5C5C5C;
            font-size: 14px; 
            font-weight: 400;
            line-height: 22px; 
            word-wrap: break-word;
            text-align: left;
          }
  
          .learn-more {
            color: #0C111A;
            font-size: 16px;
            font-weight: 500;
            word-wrap: break-word;
            cursor: pointer;
          }
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    .mission-container {
      padding: 97px 202px 125px; 
      gap: 100px;
  
      .help-list {
        display: flex;
        flex-direction: row !important; 
        justify-content: center;
        align-items: stretch; 
        width: 100%;
      }
      
  
      .help-item {
        margin: 0 10px; 
      }
    }
  }
  