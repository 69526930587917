//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.cuerpoTickets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #00000000;
  padding: 30px 0;
  min-height: 70vh;

  .infoTickets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;

    .tituloEntradas {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 20px;
      color: $colorDeFuente;
      padding-left: 10px;
      display: flex;
      gap: 5px;
    }

    .fondoSub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background-color: #cccccc79;
      border-radius: 15px;
      width: 100%;
      max-width: none;
    }

    p {
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      color: $colorDeFuente2;
    }

    .botonVerTickets {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      background-color: #99419d;
      color: #ffffff;
      padding: 8px;
      text-align: center;
      border-radius: 8px;
      text-decoration: none;
      transition: background-color 0.3s;
      margin: 5px;

      a {
        color: inherit;
        font-size: 10px;
        text-decoration: none;
      }
    }
  }

  .lineaDivisora {
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    width: 90%;
    height: 1px;
    margin: 10px auto;
  }
  
  .ticketsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
