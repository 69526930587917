// DetallesUsuarioModal.module.scss

$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.modalContent {
    font-family: $tipografia;
    color: $colorDeFuente;
    padding: 20px !important;
    border-radius: 15px !important;
    background-color: #eeeeee1a;

    h5 {
        color: $colorDeFuente;
        font-size: 15px;
        font-weight: 400;
        margin: 10px 0 2px 10px;
        text-align: left;
        width: 100%;
    }

    .label{
        font-size: 16px;
        font-weight: 600;
        border-bottom: solid 1px #ccc;
        width: 100%;
        text-align: left;
        margin-top: 8px;
    }

    .inputGroup {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 0 5px 5px 0;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    input {
        height: 40px;
        width: 100%;
        border-radius: 8px !important;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        font-size: 14px;

        &:focus {
            border-color: $violeta !important;
            box-shadow: 0 0 5px hsl(297, 41%, 20%);
            outline: none;
        }
    }

    .containerCheckbox {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        width: 95%;
      
        input {
          display: none;
        }
      
        svg {
          overflow: visible;
          color: #252525;
          height: 14px;
          width: 14px;
          margin-left: 2px;
        }
      
        .path {
          fill: none;
          stroke: #252525;
          stroke-width: 4;
          /* Ancho de trazo ajustado */
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
          stroke-dasharray: 241 9999999;
          stroke-dashoffset: 0;
        }
      
        input:checked~svg .path {
          stroke-dasharray: 70.5096664428711 9999999;
          stroke-dashoffset: -262.2723388671875;
        }
      
        span {
          margin-left: 0.5em;
          font-size: 14px;
          /* Tamaño de fuente para el texto */
        }
    }

    .inputError {
        border-color: red;
    }

    .inputValid {
        border-color: green;
    }

    .error {
        color: red;
        font-size: 12px !important;
        margin-top: -10px;
        margin-bottom: 0;
    }

    .containerCheckbox {
        width: 95%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;

        input {
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0 !important;

            svg {
                overflow: visible;
                color: #252525;
                height: 14px;
                width: 14px;
                margin-right: 10px; // Ajuste para el espacio entre el svg y el texto
            }

            .path {
                fill: none;
                stroke: #252525;
                stroke-width: 4;
                stroke-linecap: round;
                stroke-linejoin: round;
                transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
                stroke-dasharray: 241 9999999;
                stroke-dashoffset: 0;
            }
        }

        label {
            margin: 0;
            margin-left: 0.5em;
            font-size: 14px;
            width: 100%;

            p {
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        input:checked+label svg .path {
            stroke-dasharray: 70.5096664428711 9999999;
            stroke-dashoffset: -262.2723388671875;
        }
    }

    .submitButton {
        background-color: $violeta;
        color: #ffffff;
        font-family: $tipografia;
        min-height: 40px;
        min-width: 116px;
        font-weight: 600;
        margin: 0 10px 10px;
        border: none;
        width: 90%;
        border-radius: 8px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        outline: none;
        transition: background-color 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:disabled {
            background-color: #b2b2b2;
            cursor: not-allowed;
        }
    }

    .modalHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-color: $violeta;
        color: #ffffff;
        border-bottom: none;
        padding: 15px;

        .modalTitle {
            font-size: 20px;
            font-weight: 600;
        }

        p{
            font-size: 12px;
        }
    }

    .modalFooter {
        border-top: none;
        padding: 15px;
        border-radius: 0 0 15px 15px;
        display: flex;
        justify-content: center;
    }
}