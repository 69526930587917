// Define variables for colors and typography
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.bodyGR {
    padding: 20px;
    background-color: #f9f9f9;
}

.dashboard {
    min-height: 100vh;
    padding: 0.5rem;
    background: linear-gradient(135deg, #f8f9fe 0%, #ffffff 100%);

    @media (min-width: 768px) {
        padding: 2rem;
    }
}

.header {
    background: white;
    border-radius: 16px;
    padding: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

    .titulo {
        font-size: 20px;
        color: $colorDeFuente;
        margin-bottom: 1rem;
        font-weight: 800;

        @media (min-width: 768px) {
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }
    }
}

.headerStats {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.graphGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: center;
    }
  }

.statCard {
    background: linear-gradient(135deg, $violeta 0%, $rosa 100%);
    padding: 1rem;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    .statLabel {
        font-size: 0.8rem;
        opacity: 0.9;

        @media (min-width: 768px) {
            font-size: 0.9rem;
        }
    }

    .statValue {
        font-size: 1.4rem;
        font-weight: 600;
        margin-top: 0.3rem;

        @media (min-width: 768px) {
            font-size: 1.8rem;
            margin-top: 0.5rem;
        }
    }

    &.loading {
        position: relative;
        overflow: hidden;
        
        .skeletonContent {
            width: 100%;
            height: 100%;
        }

        .skeletonLabel,
        .skeletonValue {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0) 0,
                    rgba(255, 255, 255, 0.2) 20%,
                    rgba(255, 255, 255, 0.5) 60%,
                    rgba(255, 255, 255, 0)
                );
                animation: shimmer 2s infinite;
            }
        }

        .skeletonLabel {
            width: 60%;
            height: 16px;
            margin-bottom: 8px;
        }

        .skeletonValue {
            width: 80%;
            height: 28px;
        }
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.mainContent {
    display: grid;
    gap: 2rem;
}

.graphSection {
    background: white;
    border-radius: 16px;
    padding: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

    @media (min-width: 768px) {
        padding: 1.5rem;
    }
}

.actionCards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.actionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    gap: 1rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;

    i {
        font-size: 1.5rem;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    &.pagosCard {
        background: linear-gradient(135deg, #11998e 0%, #38ef7d 100%);
    }

    &.facturasCard {
        background: linear-gradient(135deg, #3a7bd5 0%, #00d2ff 100%);
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
        padding: 2rem;
        font-size: 1.1rem;
    }
}

.pagosTabla {
    width: 100%;
    overflow-x: auto;
    max-width: 1000px;
    

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        th, td {
            padding: 8px;
            border-bottom: 1px solid #ddd;
            font-size: 12px;
        }

        th {
            background-color: $violetaOscuro;
            color: #fff;
            font-weight: 500;
        }

        td {
            border: 1px solid #ccc !important;
        }

        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
    }
}

// Media queries para pantallas más grandes
@media (min-width: 768px) {
    .pagosTabla {
        table {
            th, td {
                font-size: 14px;
            }
        }
    }
}

.error {
    color: red;
    margin-top: 20px;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;

    button {
        padding: 10px 20px;
        background-color: $violeta;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        font-family: $tipografia;

        &:hover {
            background-color: $violetaOscuro;
            transform: scale(1.05);
        }

        &:disabled {
            background-color: $colorDeFuente2;
            cursor: not-allowed;
            transform: none;
        }
    }

    span {
        font-family: $tipografia;
        font-size: 16px;
        color: $colorDeFuente;
    }
}



.adminButton {
    padding: 1.5rem 3rem;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}
