.skeletonWrapper {
    width: 80%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .skeletonHeading,
  .skeletonText,
  .skeletonInput,
  .skeletonButton {
    background-color: #eeeeee;
    margin: 8px 0;
    border-radius: 4px;
  }
  
  .skeletonHeading {
    height: 32px;
    width: 60%;
  }
  
  .skeletonText {
    height: 16px;
    width: 80%;
    margin-bottom: 20px;
  }
  
  .skeletonInput {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .skeletonButton {
    height: 40px;
    width: 50%;
    margin: 20px auto 0;
  }
  
  @keyframes loading {
    0% {
      background-color: #eeeeee;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #eeeeee;
    }
  }
  
  .skeletonHeading,
  .skeletonText,
  .skeletonInput,
  .skeletonButton {
    animation: loading 1.5s infinite;
  }