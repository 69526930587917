.eventos {
    transition: all 0.5s;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin: 0px auto;
    margin-bottom: 50px;

    @media(min-width: 768px) {
        max-width: 80%;
    }
}

.contBoton{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verMas {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #c1c1c1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #b1b1b1;
    }
}