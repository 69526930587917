.container {
    padding: 50px 0;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        display: flex;
        justify-content: space-between;
        width: 90%;
        max-width: 900px;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 16px;

        .eventInfo {
            display: flex;
            align-items: flex-start;

            .eventImage {
                width: 100px;
                height: 100px;
                box-shadow: 0 0 10px #cccccc82;
                border-radius: 10px;
                margin-right: 20px;
            }

            .eventName {
                font-size: 18px;
                font-weight: bold;
                padding-bottom: 0;
                text-align: left;
            }

            .eventId {
                color: grey;
                font-size: 14px;
            }
        }

        .config {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .manageButton {
        background-color: black;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 90%;
        max-width: 900px;

        .gridItem {
            background-color: #f1f1f1;
            background-image: linear-gradient(70deg, #eeeeee, #fff, #eeeeee);
            padding: 20px;
            border-radius: 10px;
            text-align: center;
            text-decoration: none;
            color: black;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 0 10px #cccccc79;

            h3 {
                align-self: flex-start;
                color: #1b1b1b;
                font-size: 16px;
                font-size: clamp(14px, 2vw, 16px);
                font-weight: 700 !important;
            }

            .description {
                font-size: 10px;
                color: #333;
                font-size: clamp(10px, 2vw, 12px);
                align-self: flex-start;
                text-align: left;
            }
        }

        .iconContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #ccc;
            color: white !important;
            margin-bottom: 10px;
            align-self: flex-start;
            width: 50px;
            height: 50px;
            border-radius: 50%;

            svg {
                align-self: center;
            }
        }
    }

    @media (min-width: 768px) {
        .grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .eventName {
            font-size: 20px !important; /* Incremento del tamaño de fuente */
        }

        .eventId {
            font-size: 16px !important; /* Incremento del tamaño de fuente */
        }

        .gridItem h3 {
            font-size: 18px !important; /* Incremento del tamaño de fuente */
        }

        .gridItem .description {
            font-size: 12px !important; /* Incremento del tamaño de fuente */
        }
    }
}