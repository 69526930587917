// Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  background-color: #00000000;
  border-radius: 8px;

  .searchForm {
    display: flex;
    flex-wrap: nowrap;
    background-color: #00000000;
    width: 100%;
    max-width: 700px;

    .eventSearch {
      padding: 8px;
      border-radius: 20px;
      width: 80%;
      background-color: #00000000;
      border: solid 1px #ccc;
      border-radius: 8px 0 0 8px;
      color: #252525;
      transition: box-shadow 0.3s ease, width 0.3s ease, border-color 0.3s ease;

      &:focus {
        outline: none;
        width: 80%;
        border: solid 2px $violeta;
      }

      @media (min-width: 500px) {
        width: 80%;
      }
    }

    .searchButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #862f8b !important;
      border-radius: 0 8px 8px 0;
      color: white;
      border: none;
      text-align: center;
      width: 20%;
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.3s;

      &:hover,
      &:focus {
        background-color: #7e3b8a;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      }

      .buttonText {
        display: none;

        @media (min-width: 768px) {
          display: inline;
          margin-left: 3px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.eventos {
  transition: all 0.5s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  margin-bottom: 50px;
}