.infoGoogle {
  width: 100%;
  min-height: 70px;
  padding: 10px 0;
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .container {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 0 24px; 
    position: absolute;
    gap: 12px;
    transition: transform 0.5s ease-in-out;
    text-align: left; 

    &.slideInRight {
      transform: translateX(0);
      animation: slideInRight 0.5s ease-in-out;
    }

    &.slideOutLeft {
      transform: translateX(-100%);
      animation: slideOutLeft 0.5s ease-in-out;
    }

    .comments {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .comment {
        color: #000000;
        font-size: 12px;
        line-height: 17px;
        font-weight: 700;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .commentsGoogleYInforme {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .comment {
        color: #000000;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    gap: 12px;

    .image {
      height: 20px;
      width: auto;
      object-fit: contain;
      filter: grayscale(100%);
      transition: filter 0.3s ease;

      &:hover {
        filter: grayscale(0%);
      }

      @media (min-width: 768px) {
        height: 30px;
      }
    }
  }

  .iconContainer {
    display: inline-flex; 
    align-items: center; 
    margin-right: 8px; 
  }

  @media (min-width: 768px) {
    min-height: 80px;

    .container {
      align-items: center; 
      text-align: center; 
    }
    .imageContainer {
      justify-content: flex-start;
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
