/* Estilos básicos para el skeleton de staffItem */
.staffItem {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
    height: 150px; /* Altura ajustada para cubrir todos los elementos */
    animation: loading 1.5s infinite;
    border-radius: 15px;
}

@keyframes loading {
    0% {
        background-color: #eeeeee;
    }
    50% {
        background-color: #e0e0e0;
    }
    100% {
        background-color: #eeeeee;
    }
}

.staffItem .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.staffItem .contIcono {
    width: 20px;
    height: 20px; /* Ajuste de altura para el icono */
    background-color: #dddddd;
    border-radius: 50%;
    margin-right: 10px;
}

.staffItem .caracteristicaId {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.staffItem .caracteristicaId h3,
.staffItem .caracteristica span,
.staffItem .caracteristica h3 {
    background-color: #dddddd;
    width: 100px;
    height: 16px;
    margin: 4px 0;
    border-radius: 8px;
}

.staffItem .caracteristica span{
    background-color: #dddddd;
    width: 50px;
    height: 16px;
    margin: 4px 0;
    border-radius: 8px;
}

.staffItem .caracteristica {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10%;
    margin: 8px 0;
}

.staffItem .passwordContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.staffItem .passwordInput {
    background-color: #dddddd;
    width: 150px;
    height: 16px;
    margin-left: 8px;
    border-radius: 8px;
}

.staffItem .togglePassword {
    background-color: #dddddd;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    border-radius: 10px;
}

.staffItem .eliminar {
    background-color: #dddddd;
    width: 80px;
    height: 30px;
    border-radius: 8px;
    margin-top: 16px;
}

@media (min-width: 768px) {
    .staffItem {
        flex-direction: row;
        align-items: center;
    }

    .staffItem .header {
        max-width: 70px;
    }

    .staffItem .contIcono {
        width: 40px;
    }

    .staffItem .caracteristicaId {
        width: 40px;
    }

    .staffItem .caracteristica {
        padding-left: 0;
        width: auto;
    }

    .staffItem .passwordContainer .passwordInput {
        margin-left: 0;
        width: 200px; /* Aumentar el tamaño del input después de 768px */
    }

    .staffItem .eliminar {
        margin: 0;
    }
}