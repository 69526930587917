//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.ticketCont {
  border-radius: 10px;
  box-shadow: 2px 0 20px #bbbbbb;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  margin: 20px 30px;
  background: #fff;
  padding: 0;
  align-items: center;
  width: 300px;
  border: 1px solid #99419d;

  .logoTicket {
    display: flex;
    width: 100%;
    background-color: #99419d;
    border-radius: 8px 8px 0 0;
    text-align: center;
    flex-direction: column;
    justify-content: center;

    .detallesTicket {
      height: 40px;
      border-bottom: 2px dashed #ccc;
      margin: 0%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 10px;
      margin: 0;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 13px;
      vertical-align: middle;
    }

    .id {
      font-size: 15px;
    }

    .contTitulo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 25px;
      margin: 5px 0;

      h1 {
        font-weight: 900;
      }
    }

    h1 {
      font-size: 20px;
      color: #fff;
      margin: 0;
      padding: 0px;
    }
  }

  .datosTicket {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    text-align: left;
    width: 90%;

    h2 {
      padding: 10px 10px 0 10px;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      height: auto !important;
    }

    span {
      border-bottom: solid 1px #ccc;
      width: 90%;
      font-weight: 600;
      font-size: 14px;
      margin: 2px 10px;
    }
  }

  .imagenQR {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 240px;
      box-shadow: 0.5px 0 20px #bbbbbb;
      margin: 10px 0;
      border-radius: 15px;
      border: solid 1px #ccc;
    }
  }

  .btnDescargar {
    width: 200px;
    position: relative;
    height: 40px;
    margin: 10px 0;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 90%;
    border: none;
    background-color: #99419d;
    color: #ffffff;
    padding: 8px;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    border-left: 1px solid #99419d;
    border-right: 1px solid #99419d;
  }

  .loader {
    width: 90%;
    height: 40px;
    margin: 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #99419d;
    color: #ffffff;
    padding: 8px;
    border-radius: 8px;
    text-align: center;

    span {
      margin-left: 8px;
    }
  }
}