.eventContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #f8f9fa;
  border-radius: 16px;
  padding: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.auspiciado {
  font-size: 12px;
  font-weight: 400;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.logoBER {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 10px;
}

.descripcion {
  background: white;
  border-radius: 12px;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.05);

  .precios {
    font-size: 14px;
    font-weight: 700;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }

  .item {
    font-size: 12px;
    color: #4a5568;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-bottom: solid 1px #eeeeee;

    span {
      font-weight: 600;
      color: #2d3748;
    }
  }

  @media(min-width: 768px) {
    .precios {
      font-size: 16px;
    }

    .item {
      font-size: 14px;
    }
  }
}

.mapContainer {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.05);
}

.scrollContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: -0.5rem;
  padding: 0.5rem;
}

.mapLayout {
  min-width: min-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.row {
  display: flex;
  padding: 0.25rem;
  width: 100%;
}

.section {
  padding: 5px;
  margin: 2px 0.7142%;
  text-align: center;
  color: #252525;
  font-size: 11px;
  border-radius: 8px;
  width: 11.25%;
  min-width: 70px;
  min-height: 42px;
  transition: all 0.3s ease;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  @media (min-width: 640px) {
    font-size: 12px;
  }

  &:not(.free):not(.escenario) {
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 10px 0px rgb(0 0 0 / 0.5);
    }
  }
}

.pasarela {
  background: #ccc;
  padding: 12px;
  text-align: center;
  font-size: 0.75rem;
  color: #6b7280;
  border-radius: 6px;
  margin: 0.5rem 0;
  width: 100%;

  span {
    letter-spacing: 0.1em;
  }
}



// Secciones dobles (2x)
.plateaRoja,
.plateaVerde,
.plateaNegra,
.plateaDorada {
  min-width: 144px !important;
}

.plateaRoja,
.plateaVerde,
.plateaNegra,
.plateaDorada,
.palcoBlanco,
.plateaNaranja,
.palcoAzul {
  box-shadow: 0 0 10px #a2a2a2;
}

// Estilos específicos para cada sección
.sillones {
  background-color: #9ca3af;
  color: #ccc;
}

.plateaRoja {
  background-color: #ef4444;
  color: white;
}

.palcoAzul {
  background-color: #3b82f6;
  color: white;
}

.plateaVerde {
  background-color: #10b981;
  color: white;
}

.escenario {
  background-color: #6b7280;
  color: #ccc;
}

.tribuna {
  background-color: #9ca3af;
  color: #ccc;
}

.tribunaGrande {
  background-color: #9ca3af;
  color: #ccc;
}

.plateaDorada {
  background-color: #fbbf24;
  color: #1a1a1a;
}

.palcoBlanco {
  background-color: #f9fafb;
  color: #1a1a1a;
}

.plateaNegra {
  background-color: #1f2937;
  color: white;
}

// Clase para secciones gratuitas
.free {
  cursor: default;
  opacity: 0.8;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

// Estilos para el modal y selección de asientos
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.75);
  backdrop-filter: blur(4px);
  display: grid;
  place-items: center;
  padding: 1rem;
  z-index: 50;
}

.modalContent {
  background: white;
  border-radius: 16px;
  width: 90vw;
  max-width: 800px;
  height: auto;
  max-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1);

}

.modalHeader {
  position: sticky;
  top: 0;
  background: white;
  padding: 1.5rem 1rem 0.75rem;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 16px 16px 0 0;
  z-index: 100;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a1a1a;
    text-align: center;
  }
}

.mobileScroll {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  padding: 0.5rem 0;
  border-radius: 8px;

  @media (max-width: 640px) {
    display: flex;
  }
}

.seatsContainerWrapper {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.seatsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem;
  width: 100%;

  // Para secciones arriba de la pasarela
  &[data-section*="PLATEA ROJA"],
  &[data-section*="PLATEA VERDE"],
  &[data-section*="PALCO AZUL"] {
    flex-direction: column-reverse;
  }
}

.rowContainer {
  display: flex;
  justify-content: flex-start;
  gap: 0.375rem;
  padding: 0.25rem;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 640px) {
    gap: 0.5rem;
    padding: 0.5rem;

    // Clase específica para palcos
    &[data-section*="PALCO"] {
      justify-content: center;
    }
    &[data-section*="TRIBUNA NARANJA"] {
      justify-content: center;
    }
  }


}

.seatButton {
  width: 25px !important;
  height: 25px !important;
  aspect-ratio: 1 !important;
  font-size: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media (min-width: 768px) {
    width: 30px !important;
    height: 30px !important;
    font-size: 12px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    transform: scale(1.1);
  }
}

.closeIcon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #f3f4f6;
  border: none;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.2s ease;
  z-index: 20;

  &:hover {
    background: #e5e7eb;
  }
}

.confirmButtonContainer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  background: white;
  border-top: 1px solid #e5e7eb;
  z-index: 10;

  .disclaimer {
    font-size: 12px;
    color: #6b7280;
    text-align: center;
    margin-top: 8px;
    font-style: italic;
  }
}

.confirmButton {
  width: 50%;
  background: #22c55e;
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-bottom: 10px;
  
  &:hover {
    background: #16a34a;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.7);
  }
  
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(34, 197, 94, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.seatsContainerWrapper {
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  padding-bottom: 0rem;
  -webkit-overflow-scrolling: touch;
  border-radius: 16px;
  width: 98%;
  margin: 0 auto;
}

.selectedSeatsContainer {
  margin-top: 1rem;
  background: #f9fafb;
  border-radius: 12px;
  overflow: hidden;

  .seleccionados {
    background: #f3f4f6;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #1a1a1a;
  }

  .selectedSeat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background: white;
    margin: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);

    span {
      font-size: 0.875rem;
      color: #4a5568;
    }

    .removeButton {
      padding: 0.25rem 0.5rem;
      border-radius: 6px;
      border: none;
      background: #fee2e2;
      color: #ef4444;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #fecaca;
      }
    }
  }
}

// Clase para plateas (50% más grandes)
.platea {
  flex: 2 !important;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #ff6d6d !important;
  padding: 5px 10px;
  z-index: 1000;
  
  &:hover {
    color: rgb(255, 80, 80)d6d;
  }
}

// Agregar el nuevo estilo para platea naranja
.plateaNaranja {
  background-color: #fb923c;
  color: white;
}