.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -150%;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
      animation: loading 1.3s infinite;
    }
}

@keyframes loading {
    0% {
      left: -150%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 150%;
    }
}

.tipoDeTicketSkeleton {
    width: 100%;
    padding: 8px;
    max-width: 900px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: solid 1px #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
}

.numeroTipoTicketSkeleton, .inputSkeleton, .eliminarBtnSkeleton {
    width: 100%;
    margin-bottom: 10px;
}

.inputSkeleton {
    height: 42px;
}

.eliminarBtnSkeleton {
    height: 40px;
}
