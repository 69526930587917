.skeleton-container {
    max-width: 500px;
    margin: 20px auto;
  }
  
  .skeleton-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    background-color: #eee;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border-left: solid 3px #bdbdbd;
    border-bottom: solid 3px #bdbdbd;


    @media(min-width: 768px){
      width: 350px;
    }

    @media(min-width: 1024px){
      width: 500px;
    }
  }
  
  .sinCarga{
    background: #eeeeee!important;
    animation: none!important;
    margin: 20px 0;
  }
  
  .sinCarga:before{
    background: #eeeeee!important;
    animation: none!important;
    
  }

  .skeleton-chart:before {
    content: '';
    display: block;
    position: absolute;
    left: -150px; /* Inicialmente fuera del contenedor */
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e0e0e0 50%, transparent 100%);
    animation: load 1.5s infinite;
  }
  
  @keyframes load {
    0% {
      left: -150px;
    }
    100% {
      left: 100%;
    }
  }
  