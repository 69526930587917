.home {
  h2 {    
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 0;
    width: 80%;
  }

  .carouselWithRadius {
    border-radius: 10px;
    overflow: hidden; 
    width: 97%;
    margin: 0 auto;
    overflow:hidden;
  }

  @media(min-width: 768px){
    h2{
        font-size: 24px;
    }
  }
}
