.bodyLogin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;

    h1{
        text-align: center;
        width: 240px;
        margin: 10px;
        font-family: "Montserrat";
        text-align: center;
        color: #99419d;
        float: left;
        padding: 15px;
        font-size: 30px;
        margin: 0;
        font-weight: 900;
    }

    .pass {
        color: #c970ce;
    }
}