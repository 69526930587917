.bodyGR {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0;
    min-height: 70vh;

    .titulo {
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
        display: flex;
    }

    .descrip {
        font-size: 12px;
        background-color: #cccccc79;
        padding: 8px;
        color: #1b1b1b;
        width: 90%;
        border-radius: 15px;
        max-width: 900px;
    }

    .subtitulo{
        border-bottom: solid 1px #ccc;
        padding-bottom: 3px;
        width: 90%;
        max-width: 900px;
        margin: 16px 0 8px;
    }

    .listaRrpps {
        width: 90%;
        max-width: 900px;
    }

    .rrppItem {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0;
        border: solid 1px #ccc;
        border-radius: 15px;

        .contNombre {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            span{
                margin-left: 8px;
            }
        }

        button{
            font-size: 14px;
            display: flex;
            align-items: center;
            svg{
                height: 15px;
            }
        }

        svg {
            height: 20px;
        }

    }

    .agregarCont{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
        border: solid 1px #ccc;
        width: 90%;
        border-radius: 15px;
        padding: 10px 16px;
        font-size: 14px;
        max-width: 900px;

        .iconInputCont{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
        }
        input{
            border: solid 1px #ccc;
            padding: 8px;
            border-radius: 10px;
            margin-left: 8px;
            
        }

        button{
        display: flex;
        align-items: center;
            svg{
                height: 15px;
            }
        }

        svg {
            height: 20px;
        }
    }

    @media(min-width: 768px) {

        .titulo {
            font-size: 22px;
        }

        .descrip {
            font-size: 14px;
        }
    }
}