.theaterContainer {
  width: 100%;
  height: auto;
}

.descripcion{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 8px 0;

  
  p{
    font-size: 12px;

    @media(min-width: 768px){
      font-size: 14px;
    }
  }

  .precios{
    font-size: 14px;
    font-weight: 700;
    @media(min-width: 768px){
      font-size: 16px;
    }
  }

  .item{
    font-weight: 600;

    span{
      font-weight: 400;
    }
  }

  @media(min-width: 768px){
    padding: 8px 16px;
  }
}
.contMap{
  position: relative;
  width: 100%;
  height: auto;

  @media(min-width: 768px){
    width: 80%;
    margin: 0 auto;
  }
}

/* lado derecho interno */

.palco4 {
  top: 16%;
  right: 12.3%;
}

.palco6 {
  top: 23.2%;
  right: 11.7%;
}

.palco8 {
  top: 30.4%;
  right: 11.2%;
}

.palco10 {
  top: 37.3%;
  right: 11.8%;
}

.palco12 {
  top: 44.3%;
  right: 12.6%;
}

.palco14 {
  top: 51.4%;
  right: 14.4%;
}

.palco16 {
  top: 58.8%;
  right: 17.3%;
}

/* lado izquierdo interno */

.palco5 {
  top: 24.825%;
  left: 11.7%;
}

.palco7 {
  top: 31.7%;
  left: 11.5%;
}

.palco9 {
  top: 38.2%;
  left: 11.7%;
}

.palco11 {
  top: 45.3%;
  left: 12.3%;
}

.palco13 {
  top: 52.5%;
  left: 14.6%;
}

.palco15 {
  top: 58.8%;
  left: 17.5%;
}

.theaterImage {
  width: 100%;
  height: auto;
}

/* lado izquierdo externo */

.palco21 {
  top: 38.8%;
  left: 3.4%;
}

.palco23 {
  top: 47%;
  left: 4.5%;
}

.palco25 {
  top: 55.5%;
  left: 6.7%;
}

.palco27 {
  top: 63.5%;
  left: 10.5%;
}

.palco29 {
  top: 70.3%;
  left: 16.3%;
}

.palco31 {
  top: 75%;
  left: 24.3%;
}

/* lado derecho externo */

.palco22 {
  top: 38.5%;
  right: 3.2%;
}

.palco24 {
  top: 47%;
  right: 4%;
}

.palco26 {
  top: 55.4%;
  right: 6.4%;
}

.palco28 {
  top: 63%;
  right: 10.4%;
}

.palco30 {
  top: 70%;
  right: 16%;
}

.palco32 {
  top: 75.2%;
  right: 24.2%;
}

.selectedSeatsContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50px;

  .seleccionados{
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-bottom: solid 1px #ccc;
  }
  .selectedSeat{
    margin: 8px;
    padding: 8px;
    font-size: 12px;
    background-color: #eaeaea;
    border-radius: 5px;

    .removeButton{
      font-weight: 700;
      margin-left: 4px;
    }
  }
}

.palcoButton {
  position: absolute;
  background: transparent;
  border: none;
  font-size: 6px;
  font-weight: 700;
  width: 7%;
  cursor: pointer;
  padding: 5px;
  color: #a1a1a1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: 500px) {
    font-size: 8px;
    margin-top: 0.5%;
    height: 7%;
  }

  @media (min-width: 768px) {
    font-size: 10px;
    
  }
}

.palcoButton[disabled] {
  color: #ccc;
  cursor: not-allowed;
}

.anfIzq {
  height: 21% !important;
  width: 8% !important;
}

.genIzq {
  width: 9% !important;
  height: 38% !important;
}

.genDer {
  width: 9% !important;
  height: 38% !important;
}

.genMed {
  width: 30% !important;
  height: 54% !important;
}

.anfDer {
  height: 21% !important;
  width: 8% !important;
}

.invisibleButton {
  position: absolute;
  background: transparent;
  width: 10%;
  height: 10%;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-height: 60vh;
  overflow-y: scroll;
  max-width: 600px;
  position: relative;
}

.modalHeader {
  text-align: center;
  margin-bottom: 20px;
}

.seatsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.seatButton {
  width: 22px;
  height: 22px;
  font-size: 9px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  @media(min-width: 768px){
    width: 30px;
    height: 30px;
  }
}

.closeButton {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.closeButton:hover {
  background-color: #0056b3;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: #000;
}