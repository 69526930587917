//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.bodyPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;

  .tusEventos {
    font-size: 18px;
    margin: 20px auto 0;
    padding-bottom: 3px;
    border-bottom: solid 1px #ccc;
    width: 90%;
  }

  .contGraficos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media(min-width: 768px){
      
    }
  }

  .contImgYTitulos {
    position: relative;
    width: 100%;
    overflow: hidden;

    .contImg {
      display: block;
      position: relative;
      width: 100%;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.80) 50%, transparent);
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%; 
        pointer-events: none;
      }
    }

    .contTituloYSub {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: auto;
      min-width: 300px;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.15);
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 

      h1{
        margin: 0 !important; 
        color: #fff; 
        padding: 0;
        font-size: 18px;
        font-weight: 900;
      }
      h3 {
        margin: 0; 
        color: #fff; 
        padding: 0;
      }
    }
  }

  .sectionEventos {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
