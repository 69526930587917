.cuerpo {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0;
}

.titulo {
  width: 90%;
  max-width: 800px;
  font-size: 18px;
  color: #ccc;
  font-weight: 700;
  text-align: center;
  height: 40px;
  margin-top: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}

.titulo::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150%;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: loading 1.5s infinite;
}

.tabla {
  width: 90%;
  max-width: 800px;
  border-collapse: collapse;
  border-radius: 10px;
  margin-bottom: 30px;
}

.td, .th {
  background-color: #e0e0e0;
  height: 20px;
  padding: 15px;
  text-align: left;
  border: solid 1px #ccc;
  position: relative;
  overflow: hidden;
}

.td::before, .th::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150%;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: loading 1.5s infinite;
}

/* Rounded corners for the table */
.brtop {
  border-radius: 10px 10px 0 0 !important;
}

.brbottom {
  border-radius: 0 0 10px 10px !important;
}

.lineaDivisora {
  border-top: 1px solid #cdcdcd;
  box-sizing: border-box;
  width: 90%;
  height: 1px;
  margin: 10px auto;
  max-width: 780px;
}

@keyframes loading {
  0% {
      left: -150%;
  }
  50% {
      left: 0;
  }
  100% {
      left: 150%;
  }
}