.cardEvento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: #FBFBFB;
  border-radius: 8px;
  border: 0.8px solid #E4E4E4;
  margin: 32px auto;
  padding: 8px;
  max-width: 320px;
  box-shadow: 0 0 10px #ccc;

  @media (min-width: 768px) {
    width: 280px;
    margin: 20px;

    
  }

  @media (min-width: 1024px) {
    width: 320px;
  }
}

.cardEventoImgLink {
  width: 100%;
  display: inline-block;
  width: 100%;
  aspect-ratio: 1 / 1; 
  object-fit: cover;
  border-radius: 4px;
  vertical-align: middle;
  min-height: 260px;
}

.cardEventoImg {
  width: 100%;
  aspect-ratio: 1 / 1; 
  object-fit: cover;
  border-radius: 4px;
  vertical-align: middle;
}

.contenidoEvento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 8px;
}

.eventDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 60px;
  width: 100%;
  padding: 0;
}

.eventTitle,
.atributoCard,
.botonComprar,
.atributoUbicacion {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.atributoUbicacion{
  width: 60% !important;
  max-width: 120px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.botonComprar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #99419d; /* Usando color directamente */
  color: #ffffff;
  padding: 8px 16px !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  transition: background-color 0.3s;
  bottom: 0;
  max-height: 42px;
  max-width: 40%;
  cursor: pointer;
}

/* Estilos para el esqueleto */
.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}