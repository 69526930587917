.bodyEventoDetalles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
  padding-bottom: 50px;
}

.contEventoDetalles {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgFondo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(10px);
    opacity: 0.5;
    z-index: -1;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 50%, transparent);
  }
}

.contSeccionImgYDatos {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000%;
  margin: 0 auto;

  @media (min-width: 500px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 100px;
    max-width: 1000px;
  }

  .contImgEventoDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 500px) {
      width: 40%;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100%;
    }
  }

  .eventDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto 40px;
    padding: 5px;

    div {
      color: #99419d;
    }

    i {
      color: #99419d;
      font-size: 0.9em;
      margin-right: 3px;
      text-align: center;
      justify-content: center;
    }

    p {
      text-align: left;
      color: #1b1b1b;
      font-weight: 500;
      margin-bottom: 0;
      font-size: 14px;
      margin: 4px;
      word-break: break-word;
    }
  }

  .imgEvento {
    object-fit: cover;
    width: 90%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 15px;
    margin-top: 10px;
    box-shadow: 1px 0 20px #bbbbbb;
  }

  h5 {
    display: flex;
    padding-top: 5px;
    font-size: 1.7em;
    text-align: left;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }

  .descripcionEvento {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  i {
    font-size: 15px;
    width: 15px;
  }

  @media (min-width: 500px) {
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: wrap;

    .imgEvento {
      width: 300px;
      margin-left: 5%;
      margin-right: 10px;
    }

    h5 {
      font-size: 1.7em;
    }

    p {
      font-size: 0.8em;
    }
  }

  @media (min-width: 500px) {
    margin-top: 30px;

    .eventTitle {
      font-size: 1.7em;
      line-height: 37px;
      width: 100%;
      text-align: left;
    }

    .eventDetails {
      width: 60%;
      max-width: 600px;
      padding-top: 10px;
      margin: 0 0 0 20px;
      flex: 1 1 0;
    }

    .imgEvento {
      width: 90%;
      height: auto;
      min-width: 260px;
      flex: 1 1 0;
      max-width: 400px;
    }
  }

  .contEventTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #99419d;
    margin-bottom: 10px;
    text-align: center;

    .eventTitle {
      margin: 0;
      width: 100%;
      padding: 16px 5px 8px 0;
      font-size: 1.7em;
      font-weight: 600;
      text-align: left;
      color: #1b1b1b;

      @media (min-width: 768px) {
        padding: 0px 5px 8px 0;
      }
    }
  }
}

.eventPortada {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.eventoNoDisponible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 70vh;

  .imagenErrorEvento {
    width: 120px;
    margin-top: 25%;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    color: #f15656;
    width: 80%;
    padding: 0;
    margin-bottom: 25%;
  }

  @media (min-width: 768px) {
    .imagenErrorEvento {
      width: 200px;
      margin-top: 15%;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 15%;
    }
  }
}

.atributoCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  p {
    font-size: 16px;
    text-align: left;
    width: 90%;
  }

  @media (min-width: 768px) {
    p {
      font-size: 0.9em;
    }
  }
}

.contIcon {
  width: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Estilos básicos del cronómetro */
.cronometro {
  background-color: #004d99;
  /* Cambia este color por uno que represente tu marca */
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Transición suave para mostrar/ocultar el cronómetro */
.cronometro-transition {
  transition: all 0.5s ease-in-out;
}

/* Estilos para el tiempo restante */
.tiempo {
  font-weight: bold;
  font-size: 1.2em;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.titleLine {
  flex-grow: 1;
  height: 1px;
  /* Adjust the height of the line as needed */
  background-color: #99419d;
  /* Line color */
  margin: 0 10px;
  /* Spacing between the line and the text */
}

.titleText {
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 18px;
  font-weight: 700;
}

.sectionComprarTickets {
  width: 100%;
}

.tiposDeticketCont {
  width: 90%;
  margin: 20px auto;
  box-shadow: 1px 0 10px #bbbbbb;
  border-radius: 10px;
  max-width: 800px;
  background: #ffffff;
  padding-bottom: 0.1px;

  .encabezadoTickets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #99419d;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 10px 10px 0 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h4 {
        text-align: center;
        color: #252525;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0px 0px 0px 0;
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    h5 {
      text-align: left;
      color: #252525;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      padding: 8px;
      line-height: unset;
      width: auto;
      align-self: center !important;
      background-color: #eeeeee;
      border-radius: 5px;
      max-height: 35px;

      span {
        font-weight: 400;
      }
    }

    @media(min-width: 768px) {
      h4 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      h5 {
        font-size: 14px;
        max-height: 40px;
      }
    }
  }
}

.contInfoConsumiciones {
  border-bottom: 2px solid #99419d;
  padding: 16px;

  h4 {
    padding: 0 !important;
  }

  p {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.css-13cymwt-control {
  border-radius: 15px;
}

/* Estilos para el contenedor de mesas */
.contMesas {
  width: 90%;
  margin: 30px auto;
  box-shadow: 1px 0 10px #bbbbbb;
  border-radius: 10px;
  max-width: 800px;
  background: #ffffff;

  .tituloYPrecios {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #99419d;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 10px 10px 0 0;

    .contDescripcion {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      h4 {
        text-align: center;
        color: #252525;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0px 0px 0px 0;
        width: fit-content !important;
      }

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }

    h5 {
      text-align: left;
      color: #252525;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      padding: 8px;
      line-height: unset;
      width: fit-content;
      display: flex;
      flex-wrap: nowrap;
      align-self: center !important;
      background-color: #eeeeee;
      border-radius: 5px;
      max-height: 35px;

      span {
        font-weight: 400;
        margin-left: 4px;
      }
    }

    @media(min-width: 768px) {
      h4 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
      }

      h5 {
        font-size: 14px;
        max-height: 40px;
      }
    }
  }

  img {
    border-radius: 5px;
  }

  .contenedorMesas {
    margin-top: 8px;
    padding: 16px;

    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 500;
      color: #333;
    }


    @media(min-width: 768px) {
      .tituloYPrecios {

        h4 {
          font-size: 18px;
        }
      }
    }
  }
}


.tipoTicketGeneral {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #ccc !important;
  width: 95%;
  margin: 16px auto 0;
  border-radius: 10px;
  min-height: 70px;
  max-width: 800px;
  border: none;

  &:last-child {
    margin-bottom: 16px;
  }

  .resultadoCantidad {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    padding: 8px;
  }

  input[type="number"] {
    width: 40px;
    height: 40px;
    border: 1px solid #99419d;
    border-radius: 5px;
    text-align: center !important;
    font-family: "Montserrat", sans-serif;
    color: #99419d;
    font-size: 22px;
    background-color: #ffffff00;
    margin: 5px;
    outline: none;
    appearance: none;
    line-height: normal;
    -moz-appearance: textfield;
    padding-inline: 0;
  }

  h4 {
    text-align: left;
    color: #252525;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  small {
    text-align: left;
    color: #252525;
    width: 100%;
    font-size: 12px;
  }

  h5 {
    text-align: left;
    font-size: 14px;
    color: #252525;
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
  }

  .btnCantidad {
    background-color: #ffffff00;
    border: 1px solid #99419d;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 6px;
    color: #99419d;
  }

  .detalleTipoTicket {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (min-width: 768px) {
    input[type="number"] {
      width: 45px;
    }

    h4 {
      font-size: 18px;
    }
  }
}

.lineaDivisora {
  border-top: 1px solid #cdcdcd;
  box-sizing: border-box;
  width: 90%;
  height: 1px;
  margin: 10px auto;
  /* Centra el separador */
}

.totalContenedor {
  display: flex;
  flex-direction: row;
  border-radius: 10px !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background-color: #862f8b;
  color: white;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  height: 60px;
  width: 90%;
  max-width: 800px;
  margin: 5px auto 0;
  position: relative;
  overflow: hidden;
  border: none;

  div {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    align-items: baseline;
  }

  @media(min-width: 768px) {
    font-size: 16px;
  }
}


.contenedorTotalComisiones {
  background-color: #eeeeeea6;
  color: #252525;
  margin-top: 20px;
}

.totalComision {
  width: 80px;
  height: 40px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.totalCompra {
  min-width: 80px;
  width: auto;
  height: 40px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 4px;
}

.avisoRrpp {
  display: flex;
  flex-direction: row;
  border-radius: 10px !important;
  color: white;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  max-width: 800px;
  margin: 5px auto 0;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  box-sizing: content-box !important;
  margin-bottom: -15px;
  margin-top: 20px;

  span {
    font-size: 12px;
    padding: 16px;
  }

  .contNombreRrrpp {
    padding: 16px;
    font-size: 12px;
    text-align: center;
    font-weight: 800;
  }

  @media(min-width: 768px) {

    span {
      font-size: 14px !important;
    }

    .contNombreRrrpp {
      font-size: 14px !important;
      min-width: 200px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 1px 0 10px #bbbbbb;
  width: 90%;
  margin: 20px auto 0;
  border-radius: 10px;
  margin-bottom: 0;
  min-height: 70px;
  max-width: 800px;
  border: none;

  .detalleTipoTicket {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  .tituloMenu {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    margin: 0;
    padding: 0 0 8px 8px;
    line-height: normal !important;
  }

  .verMasBtn {
    align-self: center;
    background-color: #00000000;
    color: #99419d;
    height: 40px;
    border: solid 1px #99419d;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    margin: 0 10% 15px;
    width: 80%;
  }

  .verMasBtn:hover {
    background-color: #862f8b;
    color: white;
    transform: scale(1.1);
  }

  h4 {
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    padding: 8px 0 8px 0;
    margin: 0;
  }

  .consumisiones {
    margin: 16px auto;
    width: 95%;
    box-shadow: none;
    border: solid 1px #ccc;
  }

  .consumision {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    h5 {
      padding: 0 0 0 8px;
      font-size: 12px;
    }

    h4 {
      padding: 0 0 0 8px;
      word-break: break-word;
      font-size: 14px;
    }
  }

  .cantidadMenu {
    width: 30px;
    height: 35px;
  }

  .imgConsumision {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin: 0;
    box-shadow: -1px 1px 5px #bbbbbb;
    border-radius: 8px;
    overflow: hidden;
    /* Esto recortará la imagen si es más grande que 60x60 */
  }

  @media (min-width: 768px) {
    .consumision h4 {
      padding: 8px;
      font-size: 16px;
    }

    .consumision h5 {
      font-size: 14px;
    }
  }
}

.contenedorConsumiciones {
  width: 100%;
  padding-bottom: 0.1px;
}

.avisoAutenticacion {
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 1.7s ease, max-height 1.7s ease;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.avisoAutenticacionVisible {
  max-height: 1100px;
  opacity: 1;
}

.seccionBotonesDePago {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.btnIniciar {
  background-color: #862f8b;
  box-shadow: 1px 0 10px #bbbbbb !important;
  font-family: "Montserrat";
  min-height: 40px;
  min-width: 116px;
  color: white;
  border: none;
  margin: 10px;
  width: 120px;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  outline: none;
  transition: background-color 0.3s;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: auto;
}



@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mensajeError {
  color: red;
  margin-bottom: 10px;
  border: solid 1px #ccc;
  border-radius: 15px;
}

.mostrarOpcionesDePagoCont {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}