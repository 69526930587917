.cajaContainer {
    background: linear-gradient(100deg, #99419d 10%, #65126A 97.21%);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: space-between;
    padding: 0 2.5% 100px;

    .contenedorBody {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media(min-width: 768px) {
            flex-direction: row;
        }
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        gap: 8px;

        .leftButtons {
            display: flex;
            gap: 8px;
        }

        .volver, .fullscreenBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: none;
            border: none;
            cursor: pointer;
            padding: 8px;
            border-radius: 8px;
            transition: all 0.3s ease;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
                transform: scale(1.1);
            }

            svg {
                filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.7));
            }
        }

        @media(min-width: 768px) {
            width: 100%;
            margin: 6vh auto;
        }
    }

    .productos {
        width: 100%;
        margin: 0 auto;
        position: relative;
        padding: 20px 0;
        border: solid 1px #fff;
        border-radius: 10px;

        .subtituloProducto {
            color: #1b1b1b;
            background-color: #fff;
            border-radius: 8px;
            padding: 4px 8px;
            font-weight: 900;
            font-size: 18px;
            text-align: center;
            position: absolute;
            top: -14px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }

        .productosGrid {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;

            .producto {
                display: flex;
                flex-direction: column;
                max-width: 150px;
                width: 35%;
                margin: 16px;
                border-radius: 8px;
                background-color: #ffffff68;

                img {
                    border-radius: 6px 6px 0 0;
                    aspect-ratio: 1 / 1 !important;
                }

                .datos {
                    display: flex;
                    flex-direction: column;
                    
                    p {
                        font-size: 16px;
                        font-weight: 600;
                        width: 100%;
                        padding: 4px 8px;
                        background-color: #fff;
                        color: #1b1b1b;
                        word-break: break-all;
                        font-weight: 800;

                        &:last-child {
                            border-radius: 0 0 9px 9px;
                            border-bottom: none;
                            padding: 0 8px 4px;
                        }
                    }
                }

                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #ccc;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    margin: 60% auto;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .panelLateral {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: fixed;
        right: 1%;
        top: 50%;

        .totalContainer {
            position: sticky;
            top: 50%;
            transform: translateY(-50%);
            background: #99419d;
            padding: 16px;
            border-radius: 8px;
            text-align: center;
            transition: margin-top 0.3s ease;

            &.menuOpen {
                margin-top: 300px; // Ajustado para que quede cerca del menú desplegable
            }

            .aCobrar {
                color: #fff;
                font-size: 20px;
                font-weight: bold;
            }

            .row {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;

                .monto {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 2px;
                    font-size: 34px;
                    font-weight: 800;
                    color: #fff;
                    word-break: break-all;

                    &::before {
                        content: '$';
                        font-size: 34px;
                    }
                }

                .reload {
                    cursor: pointer;
                    color: #28a745;
                    margin-top: 8px;

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }


        @media (max-width: 768px) {
            width: 100%;
            justify-content: flex-start;
            position: relative;
            right: auto;
            top: auto;

            .totalContainer {
                position: relative; 
                top: auto;
                transform: none;
                margin: 16px auto 0;
                width: 80%; 
            }

            .row {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .botones {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contador {
            margin: 0 auto;
            width: 90%;
            padding: 8px;
            margin: 8px;
            max-width: 170px;

            .aCobrar {
                color: #fff;
                font-size: 16px;
            }

            .row {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
            }

            .monto {
                font-size: 22px;
                font-weight: 800;
                color: #fff;
            }

            .reload {
                width: 35px !important;
                color: #28a745;

                svg {
                    width: 40px;
                }
            }

            @media(min-width: 768px) {
                max-width: none;

                .monto {
                    font-size: 32px;
                }
            }
        }
    }

    .menuContainer {
        position: relative;

        .menuButton {
            background: none;
            border: none;
            cursor: pointer;
            color: white;
        }

        .menuDropdown {
            position: absolute;
            top: 35px;
            right: 0;
            background-color: #ffffff;
            border: 1px solid #99419d;
            border-radius: 8px;
            padding: 10px;
            width: min-content;
            z-index: 10;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 8px 0;

                    a,
                    button {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;
                        color: #99419d;
                        font-weight: bold;
                        text-decoration: none;
                        font-size: 14px;
                        background: none;
                        border: 1px solid #99419d;
                        border-radius: 8px;
                        padding: 8px;
                        width: 100%;
                        cursor: pointer;
                        transition: background-color 0.3s ease, color 0.3s ease;

                        svg {
                            width: 18px;
                            height: 18px;
                        }

                        &:hover {
                            background-color: #99419d;
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        .impresorasContainer {
            border: 1px solid #99419d;
            border-radius: 8px;
            padding: 16px;
            background-color: #ffffff;
            margin-top: 16px;

            h2 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #99419d;
                font-weight: bold;
                text-align: center;
                margin-bottom: 8px;
            }

            .impresorasList {
                border: 1px solid #99419d;
                border-radius: 8px;
                padding: 10px;
                background-color: #f9f9f9;

                p {
                    display: flex;
                    align-items: center;
                    /* Alinea ícono y texto */
                    gap: 8px;
                    /* Espaciado entre ícono y texto */
                    color: #99419d;
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover {
                        color: #65126A;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .refrescar {
                margin-top: 10px;
                padding: 8px 16px;
                border: 1px solid #99419d;
                border-radius: 8px;
                background-color: #99419d;
                color: #ffffff;
                cursor: pointer;
                text-align: center;
                display: flex;
                align-items: center;
                /* Centra icono y texto */
                justify-content: center;
                gap: 8px;
                /* Espacio entre texto e icono */

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }


        .loader {
            border: 4px solid #f3f3f3;
            border-top: 4px solid #ccc;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            margin: 60% auto;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .modalBackground {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;

            .modalContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: white;
                padding: 20px;
                border-radius: 10px;
                text-align: center;
                max-width: 400px;
                font-size: 18px;
                color: black;

                .wifi {
                    color: #ff4e4e;
                    margin-bottom: 16px;
                }

                p {
                    font-size: 14px;
                    font-weight: 700;

                    @media(min-width: 768px) {
                        font-size: 20px;
                    }
                }

                .recargar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: solid 1px #ccc;
                    border-radius: 8px;
                    padding: 8px 16px;
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 500;

                    svg {}
                }

                .reloadIcon {
                    cursor: pointer;
                    color: #1b1b1b;
                    margin-top: 20px;
                }
            }

        }
    }

    .cajaNombre {
        .nombreCaja {
            margin: 0;
            font-size: 1.2rem;
            color: #ffffff;
            font-weight: 800;
            span {
                font-weight: 400;
            }
        }
    }

    @media(min-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 5% 100px;
    }
}