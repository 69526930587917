.body2paso {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  padding: 100px 10%;
  min-height: 70vh;

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
    margin: 8px 0 16px;
  }

  p {
    font-size: 12px;
  }

}

.botonConectarMp {
  background-color: #009ee3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  text-decoration: none;
  margin-top: 20px;

  :hover {
    transition: transform 0.2s;
    color: white;
  }
}

.botonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.botonAgregar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-wrap: nowrap;
  background-color: transparent;
  color: #1b1b1b;
  border: solid 1px #1b1b1b;
  padding: 8px;
  border-radius: 5px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    transform:translateY(-2px);
  }

  @media(min-width: 768px){
    font-size: 14px;
  }
}