.productoresContainer {
  width: 90%;
  margin: 0 auto;
  background-color: white;
  border-radius: 16px;
  padding: 1.2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  
  h3 {
    color: #1b1b1b;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: #99419d;
      font-size: 1.3rem;
    }
  }
}

.productoresList {
  display: grid;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #99419d;
    border-radius: 10px;
  }
}

.productorCard {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: linear-gradient(135deg, rgba(153, 65, 157, 0.1) 0%, rgba(201, 112, 206, 0.1) 100%);
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(5px);
  }

  .productorIcon {
    svg {
      font-size: 2rem;
      color: #99419d;
    }
  }

  .productorInfo {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .nombre {
      font-weight: 600;
      color: #1b1b1b;
      font-family: "Montserrat", -apple-system, sans-serif;
      font-size: 0.9rem;
    }

    .detalles {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }

    .detalle {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      color: #5c5c5c;
      font-size: 0.8rem;
      font-family: "Montserrat", -apple-system, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      svg {
        flex-shrink: 0;
        font-size: 0.9rem;
        color: #99419d;
      }
    }
  }
}

.loading, .error {
  text-align: center;
  padding: 1.5rem;
  color: #5c5c5c;
  font-family: "Montserrat", -apple-system, sans-serif;
  font-size: 0.9rem;
}

.error {
  color: #c62828;
}

@media (min-width: 768px) {
  .productoresContainer {
    grid-column: span 1;
    width: 90%;
    max-width: 400px;
    margin: 20px;
  }

  .productoresList {
    max-height: 300px;
  }
} 