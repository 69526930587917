// Define variables for colors and typography
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.bodyGR {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 80vh;
    padding: 40px 20px !important;
    background-color: #f7f7f7;

    @media (min-width: 768px) {
        padding: 60px 20px !important;
    }
}

.header {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .titulo {
        font-weight: 900;
        font-size: 20px;
        margin-bottom: 24px;

        @media (min-width: 768px) {
            font-size: 32px;
        }
    }
}

.pagosTabla {
    width: 100%;
    overflow-x: auto;
    max-width: 1200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        border-radius: 10px;
        overflow: hidden;

        th,
        td {
            padding: 12px;
            border-bottom: 1px solid #ddd;

            @media (max-width: 767px) {
                padding: 8px;
                font-size: 12px;
            }
        }

        th {
            background-color: $violetaOscuro;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;

            @media (max-width: 767px) {
                font-size: 12px;
            }

            @media (min-width: 768px) {
                font-size: 16px;
            }
        }

        td {
            font-size: 14px;
            color: $colorDeFuente;
            border: none !important;

            @media (max-width: 767px) {
                font-size: 12px;
            }

            @media (min-width: 768px) {
                font-size: 15px;
            }
        }

        tr:nth-child(even) {
            background-color: #f9f9f9;
        }

        tr:hover {
            background-color: #f5f5f5;
        }
    }
}

.error {
    color: #f44336;
    text-align: center;
    margin: 20px 0;
    font-weight: 500;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;

    button {
        background-color: $violeta;
        color: white;
        border: none;
        padding: 10px 20px;
        font-family: $tipografia;
        font-size: 14px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s, transform 0.2s;

        &:hover:not(:disabled) {
            background-color: $violetaOscuro;
            transform: scale(1.05);
        }

        &:disabled {
            background-color: $colorDeFuente2;
            cursor: not-allowed;
            opacity: 0.7;
        }

        @media (min-width: 768px) {
            font-size: 16px;
        }
    }

    span {
        font-family: $tipografia;
        font-size: 14px;
        color: $colorDeFuente;
        margin: 0 10px;

        @media (min-width: 768px) {
            font-size: 16px;
        }
    }
}

// Estilos responsivos adicionales para móviles
@media (max-width: 767px) {
    .pagosTabla {
        margin: 0 -20px;
        border-radius: 0;

        table {
            th, td {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }
}
