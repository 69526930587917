.detallesEnvio { 
  background-color: transparent;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  color: #1b1b1b;
  min-height: 80vh;

  .card { 
    background: #f6f6f6;
    padding: 16px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

    @media (min-width: 768px) {
      max-width: 600px;
      padding: 32px;
    }
  }

  h1 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 12px;
    font-size: 20px;
    text-align: left;
    font-weight: bold;

    @media (min-width: 768px) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }

  .field { 
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    border-bottom: 1px solid #eeeeee;

    .label { 
      font-size: 12px;
      font-weight: bold;
      opacity: 0.9;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }

    .value { 
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media (min-width: 768px) {
        font-size: 16px;
        margin-top: 8px;
        max-width: 400px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .error { 
    color: #f34343;
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
  }

  .loading { 
    color: #1b1b1b;
    font-size: 19px;
    text-align: center;
  }

  .verTicketsButton { 
    color: #c970ce;

    .icon { 
      margin-right: 20px;
    }
  }

  .input { 
    font-size: 14px;
    padding: 6px;
    border: 1px solid #1b1b1b;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    color: black;

    @media (min-width: 768px) {
      font-size: 16px;
      padding: 8px;
      width: 60%;
      max-width: 300px;
    }
  }

  .valueWithIcons { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  .value { 
    font-size: 16px;
    font-weight: 500;
    color: #1b1b1b;
  }

  .editIcon { 
    font-size: 18px;
    color: #1b1b1b;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #ffe05d;
    }
  }

  .fondo { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 10px;
    background-color: #cccccc79;
    border-radius: 10px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    color: #5c5c5c;
    margin-bottom: 10px;

    h3 { 
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      justify-self: center;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      user-select: none;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }

    p{
      font-size: 12px;
      font-weight: 400;
      color: #5c5c5c;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  
  }

  @media (min-width: 768px) {
    padding: 60px 0;
  }
  
}
.submitButton {
  background-color:#99419d;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 80px;
  gap: 2px;

  @media (min-width: 768px) {
    padding: 10px 20px;
    font-size: 12px;
    width: 40%;
  }
}

.submitButton:hover {
  background-color: #c438cb;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}