//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.cuerpoTickets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: $tipografia;
  min-height: 70vh;

  .contDescripcion {
    width: 90%;
    max-width: 900px;
    margin-top: 30px;

    .fondoSub{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        padding: 10px;
        background-color: #cccccc79;
        border-radius: 15px;
    }
    .tituloMisConsumiciones {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 24px;
      color: $colorDeFuente;
      padding-left: 10px;
      display: flex;
      gap: 5px;
      svg{
        margin-bottom: -2px;
      }
    }

    p {
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      color: $colorDeFuente2;
    }

    @media (min-width: 768px) {
      width: 90%;
    }
  }

  .lineaDivisora {
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    width: 90%;
    height: 1px;
    margin: 10px auto;
  }

  .seccionConsumiciones {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
}
