.eventContainer {
  width: 100%;
  height: auto;
}

.descripcion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 8px 0;

  p {
    font-size: 12px;

    @media(min-width: 768px) {
      font-size: 14px;
    }
  }

  .precios {
    font-size: 14px;
    font-weight: 700;
    @media(min-width: 768px) {
      font-size: 16px;
    }
  }

  .item {
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  @media(min-width: 768px) {
    padding: 8px 16px;
  }
}

.contMap {
  position: relative;
  width: 100%;
  height: auto;

  @media(min-width: 768px) {
  }
}

.eventImage {
  width: 100%;
  height: auto;
}

.selectedSeatsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 50px;

  .seleccionados {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-bottom: solid 1px #ccc;
  }

  .selectedSeat {
    margin: 8px;
    padding: 8px;
    font-size: 12px;
    background-color: #eaeaea;
    border-radius: 5px;

    .removeButton {
      font-weight: 700;
      margin-left: 4px;
    }
  }
}

.invisibleButton {
  position: absolute;
  background: transparent;
  cursor: pointer;
}

.sectionA1 { top: 31.4%; left: 7.4%; width: 40%; height: 11.5%; }
.sectionA2 { top: 31.4%; right: 7.4%; width: 40%; height: 11.5%; }
.sectionB1 { top: 49%; left: 7.4%; width: 40%; height: 11.5%; }
.sectionB2 { top: 49%; right: 7.4%; width: 40%; height: 11.5%; }
.sectionC1 { top: 67%; left: 7.4%; width: 40%; height: 13%; }
.sectionC2 { top: 67%; right: 7.4%; width: 40%; height: 13%; }

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-height: 60vh;
  overflow-y: scroll;
  max-width: 600px;
  position: relative;
}

.modalHeader {
  text-align: center;
  margin-bottom: 20px;
}

.seatsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.seatButton {
  width: 22px;
  height: 22px;
  font-size: 9px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  @media(min-width: 768px) {
    width: 30px;
    height: 30px;
  }
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: #000;
}
