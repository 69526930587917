// PagoSkeleton.module.scss
.skeletonRow {
    height: 35px;
    /* Ajusta la altura de las filas del skeleton */
}

.skeletonCell {
    background-color: #eeeeee;
    border-radius: 4px;
    animation: loading 1.5s infinite;
    height: 100%;
    width: 100%;
    border: solid 1px #ccc;
}

@keyframes loading {
    0% {
        background-color: #eeeeee;
    }

    50% {
        background-color: #e0e0e0;
    }

    100% {
        background-color: #eeeeee;
    }
}