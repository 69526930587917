$skeleton-bg: #e0e0e0;
$skeleton-shimmer: #f0f0f0;

.skeleton {
  background-color: $skeleton-bg;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  // Efecto de animación de carga
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, $skeleton-shimmer 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }
}

// Animación para el shimmer
@keyframes loading {
  0% {
    left: -150%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 150%;
  }
}

.skeletonBody {
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tituloSkeleton {
  width: 90%;
  max-width: 1100px;
  height: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.subtituloSkeleton {
  width: 90%;
  max-width: 1100px;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.fondoSubSkeleton {
  width: 90%;
  max-width: 1100px;
  height: 60px;
  margin-bottom: 16px;
  border-radius: 15px;
}

.mapaSkeleton {
  width: 90%;
  max-width: 1100px;
  height: 400px;
  border-radius: 15px;
  margin-bottom: 32px;
}

.buttonSkeleton {
  width: 90%;
  max-width: 400px;
  height: 50px;
  border-radius: 8px;
}