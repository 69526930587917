.terminosYCondicionesBody {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        font-size: 18px;
        margin-top: 16px;
        font-weight: bold;
    }
    .condiciones {
        width: 80%;
        border: solid 1px #1b1b1b;
        border-radius: 8px;
        margin: 16px;
        padding: 16px;
        
        h3{
            font-size: 18px;
            margin-top: 16px;
            font-weight: bold;
        }
        
        p{
            font-size: 14px;
        }
    }
}