$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.modalContent {
    font-family: $tipografia;
    color: $colorDeFuente;
    padding: 30% 0 20px;
    border-radius: 15px !important;
    background-color: #eeeeee1a;

    .modalHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-color: $violeta;
        color: #ffffff;
        border-bottom: none;
        padding: 15px;

        .modalTitle {
            font-size: 20px;
            font-weight: 600;
        }

        p {
            font-size: 12px;
        }
    }

    .label {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        text-align: left;
    }

    .containerCheckbox {
        width: 90%;
        cursor: pointer;
        display: flex;
        margin: 16px auto 0;
        align-items: center;

        input {
            appearance: none;
            background-color: #fff;
            margin: 0;
            font: inherit;
            color: $violeta;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid #d9d9d9;
            border-radius: 0.15em;
            display: grid;
            place-content: center;

            &:checked::before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                transform: scale(1);
                transition: transform 0.2s ease-in-out;
                box-shadow: inset 1em 1em $violeta;
                clip-path: polygon(14% 44%, 33% 64%, 75% 22%, 86% 34%, 33% 85%, 14% 64%);
            }
        }

        span {
            margin-left: 0.5em;
            font-size: 14px;
            inline-size: auto;
        }
    }

    .inputError {
        border-color: red;
    }

    .inputValid {
        border-color: green;
    }

    .error {
        color: red;
        font-size: 12px !important;
        margin-top: -10px;
        margin-bottom: 0;
    }

    .submitButton {
        background-color: $violeta;
        color: #ffffff;
        font-family: $tipografia;
        min-height: 40px;
        min-width: 116px;
        font-weight: 600;
        margin: 0 10px 10px;
        border: none;
        width: 90%;
        border-radius: 8px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        outline: none;
        transition: background-color 0.3s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:disabled {
            background-color: #b2b2b2;
            cursor: not-allowed;
        }
    }

    .modalFooter {
        border-top: none;
        padding: 15px;
        border-radius: 0 0 15px 15px;
        display: flex;
        justify-content: center;
    }

    @media(min-width: 768px){
        padding: 10% 0 20px;
    }
}