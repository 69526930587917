//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.registroForm {
  width: 80%;
  max-width: 800px;
  margin: 10vh auto;
  padding: 20px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  box-shadow: 1px 0 20px #bbbbbb;
  

  .columnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textAlignCenter {
    text-align: center;
  }

  .introMisDatos{

    h1{
      font-size: 20px;
      width: 100%;
      text-align: left;
      padding: 0;
      margin-bottom: 8px;
      font-weight: 500;
    }
    p{
      font-size: 12px;
      width: 100%;
      text-align: left;
      margin-bottom: 16px;
    }

    @media(min-width: 768px){
      h1{
        font-size: 24px;
      }
      p{
        font-size: 14px;
      }
    }
  }

  .inputGroup {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0 5px 5px 0;
    width: 100%;
    flex-wrap: wrap;
  }

  label {
    display: block;
    text-align: flex-start;
    font-size: 14px;
    @media(min-width: 768px){
      font-size: 16px;
    }
  }

  input {
    border-radius: 8px;
    width: 100%;
    padding: 8px;
    border: solid 1px #ccc;
    margin-bottom: 10px;
    font-size: 14px;
  }

  button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #99419d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

h2 {
  height: auto !important;
}
