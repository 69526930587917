.productoSkeleton {
    width: 90%;
    max-width: 280px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    .skeletonImage {
        width: 100%;
        aspect-ratio: 1 / 1;
        margin-bottom: 12px;
    }

    .skeletonText {
        width: 100%;
        height: 40px;
        margin-bottom: 8px;
        margin-top: 16px;
    }

    .botonBorrar {
        width: 80px;
        height: 40px;
        border-radius: 8px;
        align-self: flex-end;
    }

    // Estilos generales para el esqueleto
    .skeleton {
        background-color: #e0e0e0;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -150%;
            height: 100%;
            width: 100%;
            background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
            animation: loading 1.5s infinite;
        }
    }
}

@keyframes loading {
    0% {
        left: -150%;
    }
    50% {
        left: 0;
    }
    100% {
        left: 150%;
    }
}