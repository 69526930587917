//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.faqMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
}

.contenidoPregunta{
    display: none;
    padding: 0 16px;
}

.faqMain h2 {
    margin-top: 10px;
    font-weight: 600;
    color: #252525;
    text-align: center;
    width: 90%;
}

.faq {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #00000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq h5 {
    cursor: pointer;
    margin: 0;
    font-weight: 500;
    padding: 10px;
    text-decoration: none;
    width: 90%;
    text-align: left;
    display: flex;  // Agregado para alinear iconos
    align-items: center; // Centra verticalmente
}

.contImgFaq img {
    width: 250px;
}

.preguntaFaq {
    max-width: 90%;
    width: 90%;
    border: solid 1px #1b1b1b;
    border-radius: 8px;
    margin: 5px;
    background-color: #c970ce28;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    @media (min-width: 768px){
        width: 720px;
    }

    @media (min-width: 1024px){
        width: 920px; 
    }
}
// diseño para los iconos
.iconoFaq {
    font-size: 1.5rem; 
    color: #1b1b1b;    
}
