.logo {
    width: 100px;
    margin: 30px;
    animation: expandirYGirar 1.5s infinite ease;
    box-shadow: 0px 0px 20px white;
    border-radius: 50%;

    @keyframes expandirYGirar {
        0% {
            transform: scale(1) rotate(0deg);
        }

        50% {
            transform: scale(1.1) rotate(180deg);
        }

        100% {
            transform: scale(1) rotate(360deg);
        }

    }
}