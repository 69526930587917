//Colores de la empresa
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$blur: 20px;
$shadow-opacity: 0.30;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;

  body {
    background-color: rgba(0, 0, 0, 0);
  }
}
.bodyScanner{
  min-height: 70vh;
}

.loaderContainer {
  margin: 150px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #252525;
  text-align: center;

  .loaderCargandoScanner {
    width: 112px;
    height: 112px;
    margin-bottom: 20px;
  }

  .logo {
    text-align: center;
    color: #99419d;
    float: left;
    padding: 15px;
    font-size: 30px;
    margin: 0;
    font-weight: 900;
    width: 100%;
    text-align: center;

    span {
      color: $rosa;
    }
  }

  .box1 {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
    animation: abox1 4s 1s forwards ease-in-out infinite !important;
  }

  .box2 {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
    animation: abox2 4s 1s forwards ease-in-out infinite !important;
  }

  .box3 {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
    animation: abox3 4s 1s forwards ease-in-out infinite !important;
  }

  .box1,
  .box2,
  .box3 {
    border: 16px solid #252525;
    box-sizing: border-box;
    position: absolute;
    display: block;
  }

  @keyframes abox1 {
    0% {
      width: 112px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    12.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    25% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    37.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    50% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    62.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }

    75% {
      width: 48px;
      height: 112px;
      margin-top: 0px;
      margin-left: 0px;
    }

    87.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    100% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  @keyframes abox2 {
    0% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    12.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    25% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    37.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    50% {
      width: 112px;
      height: 48px;
      margin-top: 0px;
      margin-left: 0px;
    }

    62.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }

    75% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }

    87.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }

    100% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }
  }

  @keyframes abox3 {
    0% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }

    12.5% {
      width: 48px;
      height: 48px;
      margin-top: 0px;
      margin-left: 64px;
    }

    25% {
      width: 48px;
      height: 112px;
      margin-top: 0px;
      margin-left: 64px;
    }

    37.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 64px;
    }

    50% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 64px;
    }

    62.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 64px;
    }

    75% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 64px;
    }

    87.5% {
      width: 48px;
      height: 48px;
      margin-top: 64px;
      margin-left: 64px;
    }

    100% {
      width: 112px;
      height: 48px;
      margin-top: 64px;
      margin-left: 0px;
    }
  }
}

.sectionScanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;

  .scannerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 5px;
    max-width: 400px;
    width: 90%;
    
  }

  .result {
    margin-top: 20px;
    text-align: center;
    width: 95%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border:solid 1px #ccc;
    margin: 10px;
    border-radius: 5px;
    padding: 10px 0;


    h2 {
      color: $violeta;
      font-size: 20px;
      font-weight: 500;
      width: 80%;
    }

    p {
      font-size: 18px;
      color: $colorDeFuente;
      font-weight: 400;
      padding: 5px 0 5px;
      border-radius: 0 0 10px 10px;
      width: 100%;
    }
  }

  .resultCarnet{
    background-color: #103778;
    
    h2{
      margin: 0;
      color: #f2f2f2 !important;
    }
    svg{
      margin-top: 8px;
      color: #f2f2f2;
    }
    p{
      color: #f2f2f2;
    }
  }

  .video {
    border-radius: 10px;
    border: solid 1px #ccc;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .tituloScan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 20px;
    text-align: center;
    width: 90%;
    color: #252525;
    padding: 8px;
    font-weight: 800 !important;

    img{
      margin-left: 4px;
    }
    svg{
      height: 20px;
      margin-left: 3px;
    }
  }

  .scannerRecords {
    width: 100%;
    max-width: 400px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tituloRegistros{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      width: 90%;
      text-align: left;
      margin-bottom: 4px;
    }
    table {
      width: 90%;
      border-collapse: collapse;
      margin-bottom: 16px;
    }

    th {
      background-color: #f2f2f2;
      text-align: center;
      font-weight: 600 !important;
    }

    th,
    td {
      border: 1px solid #dddddd;
      padding: 8px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .validarTicketId {
    margin-top: 24px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    background-color: #eeeeee1a;
    border-radius: 15px;
    box-shadow: 1px 0 20px #bbbbbb;
    margin-bottom: 30px;

    p{
      font-size: 10px;
      margin-bottom: 8px;
      text-align: left;

      @media(min-width: 768px){
        font-size: 12px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 8px;
      border: 1px solid #ccc;
      -moz-appearance: textfield;
      -webkit-appearance: none;
      appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    button {
      background-color: #99419d;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 400px;
  margin-top: 20px;
  width: 90%;

  .botonOpcion {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #252525;
    border: none;
    max-width: 190px;
    padding: 8px 4px;
    gap: 3px;
    min-width: 29%;
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0 0 10px #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 8px 4px;
    line-height: 110%;

    &:hover {
      background-color: #e0e0e0;
    }
    &.selected {
      background-color: $rosa;
      color: white;
    }

    @media(min-width: 768px){
      font-size: 14px;
    }
  }
}
