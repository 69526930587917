.btn-outline-success {
  color: #333;
  border-color: #862f8b;
}

.container {
  width: 100%;
  margin: 0 !important;
}

.headerLogo {
  font-family: 'Montserrat';
  text-align: center;
  color: #99419d !important;
  text-decoration: none;
  float: left;
  padding: 15px;
  margin: 0;
  font-size: 25px;
  font-weight: 900 !important;
}

.pass {
  color: #c970ce;
}

.navbar {
  background-color: #fff;
  justify-content: space-between;
  width: 100% !important;
  padding: 0 2.5%;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
}

.navbar-brand h1 {
  margin: 0;
  font-size: 25px;
}

.navbar-brand span {
  margin: 0;
  font-size: 25px;
}

.btn-outline-success:hover {
  background-color: #862f8b;
  border-color: #862f8b;
}

.navbar {
  font-family: "Montserrat", -apple-system, sans-serif;
}

.navItem {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 500 !important;
  margin: 10px;
  padding: 10px 16px !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18.2px;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid #99419d;
  border-radius: 6px;
  color: #99419d !important;
  box-shadow: inset 0 0 0 0 #99419d;
  font-weight: 500;

  svg {
    font-size: 13px;
    margin-left: 3px;
  }

  img {
    margin-left: 3px;
  }
}

.contSearch {
  width: 100%;
  height: fit-content;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItemEspecial {
  color: #fff !important;
  background-color: #99419d;
}

.navItem:hover {
  color: white;
  transform: scale(1.05);
}

.navItem:active {
  transform: scale(1.1);
}

.navLink {
  color: #f5f5f5;
  font-weight: 500;
}

.hamburguesa {
  border: none;
  border-color: rgba(0, 0, 0, 0) !important;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25px;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-collapse {
  width: 50%;
}

#nav-cerrarCuenta {
  border: none;
  border-color: #1b1b1b;
}

.cerrarS {
  border: none;
}