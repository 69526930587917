$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.buttonSubmit {
  font-family: $tipografia;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 8px;
  margin: 0;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  transition: background-color 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.buttonSubmit:disabled {
  cursor: default;
  color: lightgrey;
  background-color: #b2b2b2!important;
}

.buttonSubmit.filling::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  animation: fillButton 5s forwards;
}

.buttonSubmit.filling::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px; 
  border-top-color: white; 
  border-radius: 50%;
}

@keyframes fillButton {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

/* Contenedor de contenido para evitar que el loader lo oculte */
.content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Nuevo loader SVG */
.container {
  width: 28px;
  height: 28px;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  stroke-dasharray: 2, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}