// Company Colors and Typography
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.cardEvento {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #FBFBFB;
  border-radius: 8px;
  border: 0.8px solid #E4E4E4;
  margin: 32px auto;
  padding: 8px;
  max-width: 320px;
  box-shadow: 0 0 10px #ccc;

  @media (min-width: 768px) {
    width: 280px;
    margin: 20px;

  }

  @media (min-width: 1024px) {
    width: 320px;
  }

  .cardEventoImgLink {
    width: 100%;
    display: inline-block;
  }

  .cardEventoImg {
    width: 100%;
    aspect-ratio: 1 / 1 !important;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    vertical-align: middle;
  }

  .contenidoEvento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 8px;

    .eventDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 0;
      min-height: 90px;

      .eventTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: flex-start;
        text-align: center;
        font-size: clamp(14px, 4vw + 1em, 18px);

        h5 {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #1b1b1b;
          font-style: normal;
          font-weight: 700;
          line-height:normal !important;
          text-align: left;

          @media (min-width: 768px) {
            font-size: 20px;
          }

        }

        &:after {
          content: "Font size actual:" attr();
        }

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }

      .atributoCard {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        font-size: 12px;
        color: $colorDeFuente;
        font-weight: 500;
        margin: 0;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: nowrap;
      justify-content: space-between;

      .atributoUbicacion {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
        font-size: 12px;
        color: $colorDeFuente;
        font-weight: 500;
        line-height: 115%;
        width: 60%;
        margin: 0;

        .ciudadOLugar {
          font-weight: 700;
        }

        .provinciaOCiudad {
          font-weight: 500;
        }

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .botonComprar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $violeta;
      color: #ffffff;
      padding: 8px 16px !important;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      transition: background-color 0.3s;
      bottom: 0;
      max-height: 42px;
      max-width: 40%;
      cursor: pointer;

      &:hover {
        background-color: $violetaOscuro;
      }

      &.disabled {
        background-color: #ccc;
        color: #fff;
        cursor: not-allowed;
      }

      svg {
        margin-left: 5px;
        margin-top: 1px;
      }

      @media(min-width: 768px) {
        font-size: 14px;
      }
    }
  }
}