$violetaOscuro: #862f8b;
$colorDeFuente: #1b1b1b;

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7f7;
    width: 100%;
    min-height: 70vh;
    padding: 0 2.5% 40px;

    .header{
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        margin: 16px 0 auto 0;
    
        .volver{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            color: $violetaOscuro;
            padding: 0 8px;
            margin: -10px 4px 0;
            font-size: 14px;
    
            @media(min-width: 768px){
                font-size: 18px;
            }
    
            path{
                width: 40px;
            }
        }
    }

    .InformeCajaH1 {
        width: 90%;
        max-width: 900px;
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        color: $colorDeFuente;
        padding: 0;
        margin-bottom: 8px;

        span {
            font-size: 20px;
            font-weight: 400;
            line-height: 90%;
        }
    }

    .seccionDeInforme {
        width: 90%;
        max-width: 900px;
        margin-top: 20px;

        h2 {
            font-size: 18px;
            text-align: left;
            margin-bottom: 8px;
            color: $colorDeFuente;
            border-bottom: solid 1px #ccc;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 10px;
            box-shadow: 1px 0 20px #bbbbbb;

            th {
                text-align: left;
                background-color: $violetaOscuro;
                color: #fff;
                padding: 4px;
                font-size: 14px;
                font-weight: 400;
            }

            td {
                text-align: left;
                font-weight: 500;
                font-size: 12px;
                background-color: #fff;
                padding: 4px;
                border-left: solid 1px #ccc;
            }

            .brtopleft {
                border-radius: 5px 0 0 0 !important;
            }

            .brtopright {
                border-radius: 0 5px 0 0 !important;
            }

            .brbottomright {
                border-radius: 0 0 5px 0 !important;
            }

            .brbottomleft {
                border-radius: 0 0 0 5px !important;
            }

            @media(min-width: 768px){
                th{
                    font-size: 16px;
                }
                td{
                    font-size: 14px;
                }
            }
        }
    }

    .totalVendido {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        color: $violetaOscuro;
    }
}