.skeleton-chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-end;
    width: 300px;
    height: 200px;
    margin: 20px;
    padding: 10px;
    background-color: #eee;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border-left: solid 3px #bdbdbd;
    border-bottom: solid 3px #bdbdbd;

    .bar {
        width: 16%;
        height: 50%; /* Varía este valor para simular diferentes alturas */
        background-color: #ccc;
        animation: pulse 1.5s infinite ease-in-out;
    }

    .bar1 {
        width: 16%;
        height: 20%; /* Varía este valor para simular diferentes alturas */
        background-color: #ccc;
        animation: pulse 1.5s infinite ease-in-out;
      }

      .bar2 {
        width: 16%;
        height: 70%; /* Varía este valor para simular diferentes alturas */
        background-color: #ccc;
        animation: pulse 1.5s infinite ease-in-out;
      }
      .bar3 {
        width: 16%;
        height: 40%; /* Varía este valor para simular diferentes alturas */
        background-color: #ccc;
        animation: pulse 1.5s infinite ease-in-out;
      }
      .bar4 {
        width: 16%;
        height: 90%; /* Varía este valor para simular diferentes alturas */
        background-color: #ccc;
        animation: pulse 1.5s infinite ease-in-out;
      }
  }
  
  
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
  