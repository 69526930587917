.banner {
  background: linear-gradient(rgba(181, 77, 171, 0.7) 0%, rgba(76, 10, 65, 0.7) 100%), 
              url(https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/fondo-inicio.webp);
  background-size: cover; 
  width: 100%; 
  height: auto; 
  display: flex; 
  justify-content: center;
  align-items: center;
  padding: 25px;
  padding-left: 35px;
  color: #ffffff;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 1200px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    .textContent {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      text-align: left;
      font-size: 14px;
      max-width: 600px;

      @media (min-width: 768px) {
        gap: 8px;
        max-width: 60%;
        text-align: left;
      }

      @media (min-width: 1000px) {
        gap: 8px;
        max-width: 60%;
      }

      .title {
        font-size: 20px;
        font-weight: 700;
        color: #fff;

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 30px;
        }

        @media (min-width: 1000px) {
          font-size: 36px;
          line-height: 36px;
        }
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #cfcfcf;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 20px;
          width: 380px;
        }

        @media (min-width: 1000px) {
          font-size: 16px;
          line-height: 20px;
          width: 436px;
        }
      }

      .infoBox {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        padding: 2px 10px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        gap: 10px;

        @media (min-width: 768px) {
          width: 280px;
          padding: 2px 10px;
        }

        @media (min-width: 1000px) {
          width: 332px;
        }

        .infoText {
          font-size: 9px;
          font-weight: 400;
          color: #e0e0e0;

          @media (min-width: 768px) {
            font-size: 12px;
          }

          @media (min-width: 1000px) {
            font-size: 14px;
          }
        }
      }
    }

    .imageContainer {
      position: relative;
      width: 100%;
      max-width: 40%;
      height: auto;
      display: flex;
      shape-outside: none;
      box-shadow: none !important;
      justify-content: center;
      margin-top: 0;

      .phoneImageFront {
        position: absolute;
        width: 100%;
        max-width: 200px;
        top: 90px;
        right: 7vw;
        height: auto;
        object-fit: contain;
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
        border-radius: 12px;
        z-index: 2;
        transform: translateY(0);
      }

      .phoneImageBack {
        position: absolute;
        top: 70px;
        left: 5vw;
        z-index: 1;
        width: 90%;
        max-width: 200px;
        height: auto;
        object-fit: contain;
        border-radius: 12px;
        transform: translateY(0);
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
      }

      @media(min-width: 500px) {
        .phoneImageFront {
          top: 0px !important;
        }
        .phoneImageBack {
          top: 0px !important;
        }
      }
      @media (min-width: 768px) {
        width: 260px;
        height: auto;
      }

      @media (min-width: 1000px) {
        width: 300px;

        .phoneImageFront {
          top: 0px !important;
          right: 10vw;
        }
        .phoneImageBack {
          top: 0px !important;
          left: 4vw;
        }
      }

      @media (min-width: 1200px) {
      
        .phoneImageBack{
          left: 2vw;
        }
      }

    }
  }

  @media (min-width: 768px) {
    padding: 5% 15%;
  }

  @media (min-width: 1500px) {
    padding: 5% 20%;
  }
}
