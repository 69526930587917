.body {
    min-height: 100vh;
    background: #ffffff;
}

.heroSection {
    position: relative;
    height: 70vh;
    overflow: hidden;
    
    @media (min-width: 768px) {
        height: 60vh;
    }
    
    .videoBackground {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.4);
        }
        
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .heroContent {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2rem;
        
        @media (min-width: 768px) {
            padding: 4rem;
        }
        
        h1 {
            font-size: 4rem;
            margin-bottom: 2rem;
            font-weight: 700;
            
            @media (min-width: 768px) {
                font-size: 6rem;
                margin-bottom: 1rem;
            }
            
            .carnival {
                display: block;
                font-weight: 800;
                font-size: 2.5rem !important;
                color: #fff;
                text-shadow: 0 0 15px #a0a0a0b2;
                
                @media (min-width: 768px) {
                    font-size: 4rem !important;
                }
            }
            
            .location {
                display: block;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 4px;
                background: linear-gradient(45deg, #99419d, #FF416C);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .heroDescription{
            padding-top: 16px;
            
            @media (min-width: 768px) {
                padding-top: 24px;
            }

            p{
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                
                @media (min-width: 768px) {
                    font-size: 20px;
                    margin-bottom: 12px;
                }
            }
        }

        .countdown {
            display: flex;
            gap: 8px;
            justify-content: center;
            font-size: 9px;
            color: #333;
            
            @media (min-width: 768px) {
                gap: 16px;
                font-size: 12px;
            }
        
            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                background-color: #fff;
                
                @media (min-width: 768px) {
                    padding: 16px 24px;
                }
        
                span {
                    font-size: 16px;
                    margin-right: 2px;
                    font-weight: bold;
                    
                    @media (min-width: 768px) {
                        font-size: 24px;
                        margin-right: 4px;
                    }
                }
            }
        } 
    }
}

.navigationTabs {
    text-align: center;
    padding: 16px;
    background-color: #f6f6f6;
    
    @media (min-width: 768px) {
        padding: 24px;
    }
    
    h2 {
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        
        @media (min-width: 768px) {
            font-size: 24px;
            gap: 1rem;
        }
    }
}

.paymentMethods {
    padding: 16px;
    text-align: center;
    
    @media (min-width: 768px) {
        padding: 32px;
    }
    
    h3 {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        
        @media (min-width: 768px) {
            font-size: 20px;
            margin-bottom: 16px;
        }
    }
}

.contenedorMetodosDePago {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;
    flex-wrap: nowrap;
    
    @media (min-width: 768px) {
        gap: 16px;
    }
}

.contenedorMetodosDePago div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: 40px;
    border: solid 1px #252525;
    padding: 6px 8px;
    margin: 0 5px;
    border-radius: 5px;
    
    @media (min-width: 768px) {
        font-size: 12px;
        width: 60px;
        padding: 8px 12px;
    }
}

.contenedorMetodosDePago img {
    padding: 0 5px;
    
    @media (min-width: 768px) {
        padding: 0 8px;
    }
}

.contenedorMetodosDePago .mc {
    width: 40px;
    height: 17.5px;
    
    @media (min-width: 768px) {
        width: 60px;
        height: 26px;
    }
}

.contenedorMetodosDePago .mp {
    width: 70px;
    height: auto;
    
    @media (min-width: 768px) {
        width: 100px;
    }
}

.contenedorMetodosDePago .visa {
    width: 50px;
    height: 12.5px;
    
    @media (min-width: 768px) {
        width: 75px;
        height: 19px;
    }
}

.eventos {
    width: 90%;
    margin: 0 auto;
    background: #ffffff;
    
    @media (min-width: 768px) {
        width: 80%;
        max-width: 1200px;
    }
    
    .eventoscont {
        width: 100%;
        transition: all 0.5s;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0px;
        margin-bottom: 50px;
        
        @media (min-width: 768px) {
            gap: 24px;
            margin-bottom: 80px;
        }
    }
}

.loaderContainer {
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (min-width: 768px) {
        min-height: 70vh;
    }
}
