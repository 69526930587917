//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.whatsAppButton {
  background-color: #ffffff00;
  border: solid 2px #25d366;
  color: #25d366;
  padding: 8px 16px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  transition: background-color 0.3s;
  margin: 10px auto;
  font-weight: 500;
  min-width: 220px;

  svg{
    color: #25d366;
  }

  &:hover {
    background-color: #25d366; 
    color: #fff;
    transition: cubic-bezier();
    svg{
        color: #fff;
      }
  }


}
