/* Skeleton.module.css */
.bodyCrearEventoSkeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.eventoFotoSkeleton {
  width: 60px;
  height: 60px;
  background-color: #ccc;
  border-radius: 30px;
  margin-bottom: 20px;
}

.contFormSkeleton {
  width: 90%;
  max-width: 900px;
  border-radius: 15px;
  margin-top: 20px;
  padding: 20px;

  @media (min-width: 768px) {
    width: 80%;
    margin-right: 10%;
    padding: 30;
  }
}

.tituloSkeleton,
.subtituloSkeleton,
.inputSkeleton,
.divisorSkeleton {
  background-color: #ccc;
  margin: 10px 0;
}

.tituloSkeleton {
  height: 30px;
  width: 50%;
}

.subtituloSkeleton {
  height: 15px;
  width: 80%;
  margin-bottom: 30px;
}

.boton{
    border: none;
    padding: 0.5rem 1rem;
    margin: 0;
    text-decoration: none;
    width: 80%;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    appearance: none;
    margin: 30px auto 0;
}

.inputSkeleton {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.divisorSkeleton {
  height: 1px;
  margin: 20px 0;
  width: 100%;
  background-color: #ddd;
}

.label{
    width: 40%;
    height: 25px;
    margin: 0 auto 0 0;
}

.label, .inputSkeleton, .tituloSkeleton, .subtituloSkeleton, .boton{
    animation: loading 1.5s infinite;
}

.eventoFoto {
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 10%;
    height: 100%;
    border-radius: 15px 0 0 15px;

    img {
      width: 100px;
      overflow: hidden;
      background-size: cover;
      margin-top: 30px;
      margin-left: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@keyframes loading {
    0% {
      background-color: #eeeeee;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #eeeeee;
    }
  }