//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.copyableLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(115, 115, 115, 0.3);

  .copyableLinkInput {
    width: 85%;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 1.5;
    color: $colorDeFuente;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:read-only {
      cursor: text;
    }
  }

  .copyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    background-color: $violetaOscuro;
    border: none;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: darken($violetaOscuro, 10%);
    }

    svg {
      height: 10px;
      margin-right: 5px;
      transition: transform 0.3s ease; // Suaviza la transición del ícono
    }
  }
}