.image-container {
  position: relative;
}

.skeleton-loader {
  position: absolute;
  border-radius: 10px !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  overflow: hidden;
}

.skeleton-loader::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150%;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: loading 1.3s infinite linear;
}

@keyframes loading {
  0% {
    left: -150%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 150%;
  }
}

.lazy-image {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  object-fit: cover;
  border-radius: 10px !important;
}

.lazy-image.visible {
  opacity: 1;
}

.lazy-image.hidden {
  opacity: 0;
}