//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.parteFormLogin a {
  font-size: 14px;
  color: #1b1b1b;
  font-weight: bold;
  text-decoration: none;
  width: 100%;
}

.inputError {
  border-color: red;
}

.inputValid {
  border-color: green;
}

.error {
  color: red;
  font-size: 12px !important;
  margin-top: -10px;
  margin-bottom: 0;
}

.parteFormLogin p {
  font-size: 14px;
}

.errorMesagge {
  color: red;
  border: solid 1px red;
  border-radius: 10px;
}

.BtCrearCuenta {
  background-color: rgb(178, 178, 178);
  font-family: "Montserrat";
  min-height: 40px;
  min-width: 116px;
  color: #ffffff;
  font-weight: 600;
  margin: 0 10px 10px;
  border: none;
  width: 90%;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 0;
}

.loginForm {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px;
  background-color: #eeeeee1a;
  border-radius: 15px;
  margin: 0 auto;

  .o {
    margin-top: 10px;
    text-align: center !important;
  }

  h3 {
    font-family: $tipografia;
    color: $colorDeFuente;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }

  h4 {
    margin: 0 0 15px 0 !important;
    font-size: 16px;
    text-align: center;
  }

  h5 {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0 2px 10px;
    text-align: left;
    width: 100%;
    line-height: normal;
  }

  input {
    height: 40px;
    width: 100%;
    border-radius: 8px !important;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    font-size: 14px;

    &:focus {
      border-color: #99419d !important;
      box-shadow: 0 0 5px hsl(297, 41%, 20%);
      outline: none;
    }
  }

  @media (min-width: 768px) {
    box-shadow: none;
    padding: 20px 20px 0 20px;
    max-height: none;

    label {
      font-size: 14px;
    }
  }
}

.nright {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.containerCheckbox {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  width: 95%;

  input {
    display: none;
  }

  svg {
    overflow: visible;
    color: #252525;
    height: 14px;
    width: 14px;
    margin-left: 2px;
  }

  .path {
    fill: none;
    stroke: #252525;
    stroke-width: 4;
    /* Ancho de trazo ajustado */
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
    stroke-dasharray: 241 9999999;
    stroke-dashoffset: 0;
  }

  input:checked~svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
  }

  span {
    margin-left: 0.5em;
    font-size: 14px;
    /* Tamaño de fuente para el texto */
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 14px;
    color: $colorDeFuente;
    font-weight: bold;
    text-decoration: none;
  }

  p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.formulario {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;

  label {
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0 2px 10px;
    text-align: left;
    width: 100%;
  }
}

.togglePassword {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
  top: 0;
  padding: 0;
  height: 40px;
}

.inputGroup {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0 5px 5px 0;
  width: 100%;
  flex-wrap: wrap;
}

.fotoSignUp {
  @media (min-width: 768px) {
    display: block;
    width: 50%;
    height: 850px;
    overflow: hidden;
    background-image: url("https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/login.signup.signupProductor/imgSignup.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px 0 0 15px;
    border-right: solid 1px #cdcdcd;
  }
}

.contSubmit {
  width: 100%;
  margin-bottom: 10px;
}

.containerCheckbox {
  width: 95%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 !important;

    svg {
      overflow: visible;
      color: #252525;
      height: 14px;
      width: 14px;
      margin-right: 10px; // Ajuste para el espacio entre el svg y el texto
    }

    .path {
      fill: none;
      stroke: #252525;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
      stroke-dasharray: 241 9999999;
      stroke-dashoffset: 0;
    }
  }

  label {
    margin: 0;
    margin-left: 0.5em;
    font-size: 14px;
    width: 100%;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  input:checked+label svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
  }
}