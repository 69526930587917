.cuerpo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titulo {
  width: 90%;
  background-color: #e0e0e0;
  height: 40px;
  max-width: 800px;
  border-radius: 10px;
  margin-top: 20px;
}

.tabla {
  width: 90%;
  max-width: 800px;
  border-collapse: collapse;
  border-radius: 10px;
  margin-bottom: 30px;
}

.brtopleft {
  border-radius: 10px 0 0 0 !important;
}

.brtopright {
  border-radius: 0 10px 0 0 !important;
}

.brbottomright {
  border-radius: 0 0 10px 0 !important;
}

.brbottomleft {
  border-radius: 0 0 0 10px !important;
}

.brtop {
  border-radius: 10px 10px 0 0 !important;
}

.brbottom {
  border-radius: 0 0 10px 10px !important;
}

.lineaDivisora {
  border-top: 1px solid #cdcdcd;
  box-sizing: border-box;
  width: 90%;
  height: 1px;
  margin: 10px auto;
  max-width: 780px;
}

.td {
  background-color: #e0e0e0;
  height: 20px;
}

.th {
  background-color: #d3d3d3;
  height: 20px;
}

/* Estilos adicionales para mejorar el aspecto del esqueleto */
.th,
.td {
  padding: 15px;
  text-align: left;
  border: solid 1px #ccc;
}

/* Animación de carga para el esqueleto */
@keyframes loading {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}

.titulo {
  animation: loading 1.3s infinite;
}

.td {
  animation: loading 1.3s infinite;
}
