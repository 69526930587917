#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 30px;
  height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  transition-duration: .5s;
}

.bars {
  width: 100%;
  height: 2px !important;
  background-color: #99419d;
  border-radius: 5px;
}

#bar2 {
  transition-duration: .8s;
}

#bar1,#bar3 {
  width: 80%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  height: 3px;
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar1 {
  width: 80%;
  height: 3px;
  transform: rotate(45deg);
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 80%;
  height: 3px;
  transform: rotate(-45deg);
  transition-duration: .5s;
}

#checkbox:checked + .toggle {
  transition-duration: .5s;
  transform: rotate(180deg);
}
