.body2paso {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  padding: 10%;
  margin: 0 auto;
  max-width: 900px;
  min-height: 70vh;

  h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  p {
    margin-top: 3px;
    font-size: 12px;
  }
  .eventos{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px #ccc;
    border-radius: 15px;
    margin-top: 20px;
    padding: 16px;

    .botonConectarMp {
      background-color: #009ee3;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 16px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      font-weight: 500;
      text-decoration: none;
      :hover {
        transition: transform 0.2s;
        color: white;
      }
    }
    
  }

  @media (min-width:768px) {
    padding: 0;
  }
}

