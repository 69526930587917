// Loader.module.scss
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
  }
  
  .ball {
    --size: 10px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    margin: 0 6px;
    animation: bounce 1.5s ease infinite;
  }
  
  .blue {
    background-color: #4285f5;
  }
  
  .red {
    background-color: #ea4436;
    animation-delay: 0.25s;
  }
  
  .yellow {
    background-color: #fbbd06;
    animation-delay: 0.5s;
  }
  
  .green {
    background-color: #34a952;
    animation-delay: 0.75s;
  }
  
  @keyframes bounce {
    50% {
      transform: translateY(-25px);
    }
  }
  