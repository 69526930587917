/* Estilos para el esqueleto */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      left: -150%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 150%;
    }
  }
  
  .eventPortada {
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
    max-width: 1000px;
    margin: 10px auto 300px;
  }
  
  .contImgEventoDetails {
    margin-top: 15px;
    width: 90%;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
  }

  .lineaDivisora {
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    width: 90%;
    height: 1px;
    margin: 10px auto; 
  }
  
  .contImgEventoDetails .skeleton {
    width: 100%;
    height: 0;
    padding-top: 100%; 
    border-radius: 15px;
  }
  
  .eventDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 90%; 
    margin-top: 20px; 
  }
  
  .eventTitle .skeleton, .atributoCard .skeleton, .descripcionEvento.skeleton {
    width: 100%;
    margin: 10px 0;
  }
  
  .eventTitle .skeleton {
    height: 20px;
  }
  
  .atributoCard .skeleton {
    height: 15px;
  }
  
  .descripcionEvento.skeleton {
    height: 60px;
  }
  
  @media (min-width: 500px) {

    .eventPortada {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center; 
      margin-top: 30px;
    }
  
    .contImgEventoDetails {
      width: 40%; 
      height: auto;
        flex: 1 1 0;
        border-radius: 15px;
        box-shadow: 1px 0 20px #bbbbbb;
        max-width: 350px;
    }
  
    .eventDetails {
      width: 60%; 
      margin-left: 20px;
    }
  }
  