.banner {
  background-image: url(https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/recital.webp);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 55px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;

  .content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .title {
    font-size: 22px;
    font-weight: 800;
    line-height: 24.64px;
    word-wrap: break-word;
    text-shadow: 0 0 10px rgba(24, 24, 24, 0.5);
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    word-wrap: break-word;
    text-shadow: 0 0 10px rgba(24, 24, 24, 0.5);
  }

  .button {
    padding: 8px 18px;
    background-color: #99419D;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 15px rgba(24, 24, 24, 0.5);
  }

  @media (min-width: 768px) {
    height: 35vh;
    background-position: bottom;
    background-size: cover;
    background-position: center 60%;
  }
  @media (min-width: 1024px) {
    background-position: center 65%;
  }
}
