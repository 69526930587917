$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.container {
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #f7f7f7;
  font-family: $tipografia;

  @media (min-width: 768px) {
    padding: 40px;
  }
}

.header {
  max-width: 1200px;
  margin: 0 auto 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: $tipografia;
  font-size: 14px;
  color: $colorDeFuente;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.statusBadge {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  background: #f8f9fa;
  font-size: 14px;
  font-weight: 500;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    
    &.success {
      background-color: #4CAF50;
      box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
    }
    
    &.pending {
      background-color: #ff3d3d;
      box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
    }
  }
}

.idSection {
  h2 {
    font-size: 24px;
    margin: 0;
  }

  .type {
    color: $colorDeFuente2;
    font-size: 14px;
  }
}

.grid {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;

  h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    color: $violetaOscuro;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-size: 12px;
    color: $colorDeFuente2;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .value {
    font-size: 16px;
    color: $colorDeFuente;
  }
}

.actions {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.pdfButton, .refacturarButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-family: $tipografia;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  text-decoration: none;

  &:hover:not(:disabled) {
    transform: translateY(-1px);
  }
}

.pdfButton {
  background-color: #f8f9fa;
  color: $colorDeFuente;
  border: 1px solid #dee2e6;

  &:hover {
    background-color: #e9ecef;
  }
}

.refacturarButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $violeta;
  color: white;
  padding: 4px 8px;
  max-width: 200px !important;
  margin-left: 10px !important;
  
  .buttonIcon {
    margin-right: 8px;
  }

  &:hover:not(:disabled) {
    background-color: $violetaOscuro;
  }

  svg {
    font-size: 20px;
    flex-shrink: 0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-size: 18px;
  color: $colorDeFuente;
}

.error {
  color: #dc3545;
}

.fullWidth {
  grid-column: 1 / -1;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;

  h3 {
    color: #856404;
    display: flex;
    align-items: center;
    gap: 8px;

    .alertIcon {
      color: #856404;
      font-size: 20px;
    }
  }
}

.afipResponses {
  gap: 12px;
}

.afipResponse {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  font-size: 14px;
  color: #856404;

  .responseNumber {
    background-color: #856404;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    flex-shrink: 0;
  }

  svg {
    flex-shrink: 0;
    margin-top: 2px;
    font-size: 18px;
  }

  span {
    line-height: 1.5;
  }
}

.mensajeError,
.mensajeExito {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 14px;
  
  svg {
    font-size: 20px;
    flex-shrink: 0;
  }
}

.mensajeError {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.mensajeExito {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #c8e6c9;
} 