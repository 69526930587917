$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.autenticadorUnificado {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-family: $tipografia;

  h3 {
    font-family: $tipografia;
    color: $colorDeFuente;
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }

  .googleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .separator {
    text-align: center;
    margin: 20px 0;
    color: #666;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 45%;
      height: 1px;
      background-color: #ddd;
    }

    &::before { left: 0; }
    &::after { right: 0; }
  }

  .emailSection {
    position: relative;

    .emailInput {
      height: 40px;
      width: 100%;
      border-radius: 8px !important;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      font-size: 14px;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        border-color: $violeta !important;
        box-shadow: 0 0 5px hsl(297, 41%, 20%);
        outline: none;
      }

      &.inputError {
        border-color: red !important;
        
        &:focus {
          box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
        }
      }

      &.inputValid {
        border-color: green !important;
        
        &:focus {
          box-shadow: 0 0 5px rgba(0, 128, 0, 0.5) !important;
        }
      }
    }

    .loadingLine {
      position: absolute;
      bottom: 15px;
      left: 1%;
      width: 98%;
      height: 2px;
      background-color: transparent;
      overflow: hidden;
      border-radius: 10px;
      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 30%;
        background-color: $violeta;
        animation: loadingLineAnimation 1.5s infinite;
      }
    }
  }

  .label{
    font-size: 15px;
    font-weight: 400;
    margin: 0px 0 2px 4px;
    text-align: left;
    width: 100%;

    &:first-child{
      margin-top: 10px;
    }
  }

  .error {
    color: red;
    font-size: 12px !important;
    margin-bottom: 0;
    margin-top: 4px;
  }

  .passwordSection {
    margin-bottom: 20px;

    .inputGroup {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 0 5px 5px 0;
      width: 100%;
      flex-wrap: wrap;

      .passwordInput {
        height: 40px;
        width: 100%;
        border-radius: 8px !important;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        font-size: 14px;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;

        &:focus {
          border-color: $violeta !important;
          box-shadow: 0 0 5px hsl(297, 41%, 20%);
          outline: none;
        }

        &.inputError {
          border-color: red !important;
          
          &:focus {
            box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
          }
        }

        &.inputValid {
          border-color: green !important;
          
          &:focus {
            box-shadow: 0 0 5px rgba(0, 128, 0, 0.5) !important;
          }
        }
      }

      .togglePassword {
        background: none;
        border: none;
        position: absolute;
        right: 10px;
        cursor: pointer;
        height: 100%;
        align-items: center;
        display: flex;
        top: 0;
        padding: 0;
        height: 40px;
        color: #666;
      }
    }
  }
}

.registerFields {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .inputField {
    width: 100%;

    .textInput,
    .passwordInput {
      height: 40px;
      width: 100%;
      border-radius: 8px !important;
      padding: 10px;
      border: 1px solid #ccc;
      font-size: 14px;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        outline: none;
      }
    }
  }

  .inputGroup {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0 5px 5px 0;
    width: 100%;
    flex-wrap: wrap;

    .togglePassword {
      background: none;
      border: none;
      position: absolute;
      right: 10px;
      cursor: pointer;
      height: 100%;
      align-items: center;
      display: flex;
      top: 0;
      padding: 0;
      height: 40px;
      color: #666;
    }
  }
}

.registerTitle {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.containerCheckbox {
  width: 95%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 !important;

    svg {
      overflow: visible;
      color: #252525;
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }

    .path {
      fill: none;
      stroke: #252525;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
      stroke-dasharray: 241 9999999;
      stroke-dashoffset: 0;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      text-align: left;

      a {
        color: $violeta;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  input:checked + label svg .path {
    stroke-dasharray: 70.5096664428711 9999999;
    stroke-dashoffset: -262.2723388671875;
  }
}

@keyframes loadingLineAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(360%);
  }
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  p {
    font-size: 12px;
    margin-bottom: 5px;
    color: $colorDeFuente;
  }

  .link {
    font-size: 12px;
    color: $violeta;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.errorGlobal {
  color: red;
  font-size: 14px !important;
  text-align: center;
  padding: 8px;
  margin: 10px 0;
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
}

.googleAccountMessage {
  text-align: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 600;

  p {
    color: $colorDeFuente;
    font-size: 14px;
    margin: 0;
  }
}

.label {
  display: block;
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 5px 0;
  text-align: left;
  width: 100%;
  color: $colorDeFuente;
}

.inputError {
  border-color: red !important;
  
  &:focus {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
  }
}

.inputValid {
  border-color: green !important;
  
  &:focus {
    box-shadow: 0 0 5px rgba(0, 128, 0, 0.5) !important;
  }
}