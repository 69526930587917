//Colores de la empresa
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.bodyGR {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f7f7;
    min-height: 70vh;
    width: 100%;
    padding: 40px 0;

    .header {
        width: 90%;
        max-width: 900px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    
        .titulo {
            font-size: 18px;
            font-weight: 800;
            margin: 0;
            padding-right: 8px;
            width: 60%;

            span{
                font-size: 16px;
                font-weight: 400;
            }
        }

        .contIcon{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 16px;

            p{
                font-size: 12px;
            }

            @media(min-width: 850px){
                display: none;
            }
        }
        
        .downloadButton {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #1d6b41;
            color: white;
            border: none;
            font-weight: 600;
            border-radius: 5px;
            padding: 8px;
            width: 40%;
            height: auto;
            max-width: 200px;
            font-size: 10px;
            cursor: pointer;
            text-align: center;
    
            svg {
                width: 20px;
            }
        }
    
        @media(min-width: 768px) {
            .titulo {
                font-size: 24px;
            }
    
            .downloadButton {
                font-size: 12px;
                max-width: 230px;
            }
        }
        @media(min-width: 850px){
            margin-bottom: 16px;
        }
    }

    .pagosTabla {
        width: 90%;
        max-width: 900px;
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch;
        margin-bottom: 16px;
        box-shadow: 1px 0 20px #bbbbbb;


        table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 10px;

            th, td {
                padding: 8px;
                text-align: left;
            }

            th {
                background-color: $violetaOscuro;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
            }

            td {
                background-color: #fff;
                font-size: 12px;
                font-weight: 500;
                border-left: solid 1px #ccc;

                &:first-child {
                    border-radius: 5px 0 0 5px;
                }

                &:last-child {
                    border-radius: 0 5px 5px 0;
                }
            }

            @media(min-width: 768px) {
                th {
                    font-size: 16px;
                }

                td {
                    font-size: 14px;
                }
            }
        }

        .error {
            color: red;
            font-size: 14px;
            text-align: center;
            margin-top: 20px;
        }
    }

    .loadMoreBtn {
        display: block;
        margin: 20px auto;
        padding: 10px 20px;
        background-color: $violetaOscuro;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: 1px 0 10px #bbbbbb;

        &:hover {
            background-color: darken($violetaOscuro, 10%);
        }

        &:disabled {
            background-color: lighten($violetaOscuro, 20%);
            cursor: not-allowed;
        }
    }
}