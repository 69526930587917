.foundersSection {
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 60px 24px 80px; 
  background: linear-gradient(99deg, #732E68 0%, #651B59 75%);
  gap: 16px;

  .textContent {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      font-size: 24px; 
      font-weight: 600;
      line-height: 28px;
      color: white;
      word-wrap: break-word;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      color: white;
      word-wrap: break-word;

      span {
        &:nth-child(2) {
          font-weight: 400; 
        }
      }
    }

    h5 {
      font-size: 14px;
      line-height: 24px;
      color: white;
      word-wrap: break-word;

      span {
        &:nth-child(2) {
          font-weight: 600; 
        }
      }
    }
  }

  .imageContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 38px;

    img {
      width: 100%; 
      height: auto;
      border-radius: 8px;
      box-shadow: 0 0 20px #ccc;
      max-width: none; 
    }
  }

  @media (min-width: 1000px) //cambie a 1000 para que el conetendor no se parta y quede afuera la imagen
  {
    flex-direction: row; 
    justify-content: space-between; 
    padding: 97px 202px 125px;

    .textContent {
      width: auto; 
      flex: 1; 
      opacity: 1; 
    }

    .imageContent {
      width: auto; 
      flex: 1; 
      justify-content: flex-end; 
      
      img {
        width: 600px;  
        height: 325px; 
        object-fit: cover; 
      }
    }
  }
}
