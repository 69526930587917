$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.contenedorPasos {
    text-align: center;
    padding: 60px 20px;

    .titulo{
        font-size: 24px;
        font-weight: 700;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        @media (min-width: 768px) {
            font-size: 32px;
        }
    }

    .subtitulo{
        font-size: 16px;
        font-weight: 400;
        width: 80%;
        margin: 0 auto;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 18px;
        }
    }

    .pasos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        position: relative;
    
        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            &:before {
                content: '';
                position: absolute;
                top: 30px;
                left: 0;
                right: 0;
                width: 70%;
                margin: 0 auto;
                height: 2px;
                background: repeating-linear-gradient(
                    to right,
                    #ccc,
                    #ccc 10px,
                    transparent 10px,
                    transparent 20px
                );
                z-index: -1;
            }
        }

        .pasoItem {
            display: flex;
            align-items: center;
            align-items: flex-start;
            text-align: left;
            width: 90%;
            margin-bottom: 20px;
            position: relative; 
            z-index: 1;
        
            @media (min-width: 768px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
                max-width: 300px;
                margin-bottom: 0;
                padding: 0 30px;
            }

            .contNumero{
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
                margin-right: 8px;

                .numero {
                    background-image: linear-gradient(45deg, $rosa , $violetaOscuro);
                    color: white;
                    border-radius: 50%;
                    width: 40px ;
                    height: 40px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                
                    @media (min-width: 768px) {
                        font-size: 24px;
                        width: 60px;
                        height: 60px;
                        margin: 0;
                    }
                }
            }

            .conTexto{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h3{
                    font-weight: 700;
                    font-size: 18px;
                    text-align: left;

                    @media(min-width: 768px){
                        text-align: center;
                        font-size: 20px;
                        padding-top: 16px;
                    }
                }
                p {
                    font-size: 14px;
                    text-align: left;
                    font-weight: 400;

                    @media(min-width: 768px){
                        text-align: center;
                        font-size: 17px;
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}