.bodyGR {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0;
    min-height: 70vh;

    .titulo {
        font-size: 20px;
        font-weight: 500;
        width: 90%;
        max-width: 900px;
        padding-left: 8px;
        text-align: left;
        margin-top: 20px;
        display: flex;

    }

    .descrip {
        font-size: 12px;
        background-color: #cccccc79;
        padding: 8px;
        color: #1b1b1b;
        width: 90%;
        border-radius: 15px;
        max-width: 900px;
    }

    .subtitulo {
        border-bottom: solid 1px #ccc;
        padding-bottom: 3px;
        width: 90%;
        max-width: 900px;
        margin: 16px 0 8px;
    }

    .listaStaff {
        width: 90%;
        max-width: 900px;
        
            .agregar{
                display: flex;
                align-items: center;
            }
    }

    .staffItem {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0;
        border: solid 1px #ccc;
        border-radius: 15px;

        .header {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .contIcono {
                width: 10%;
            }

            .caracteristicaId {
                width: 90%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 4px;

                span {
                    font-size: 14px;
                    font-weight: 600;
                }

                h3 {
                    font-size: 14px;
                }
            }
        }
        
        .caracteristica {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-left: 10%;
            margin: 8px 0;

            span {
                font-size: 14px;
                font-weight: 600;
            }

            h3 {
                font-size: 14px;
            }
        }

        .contNombre {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 8px;
            }
        }

        .passwordContainer {
            display: flex;
            align-items: center;

            .passwordInput {
                border: solid 1px #ccc;
                padding: 8px;
                border-radius: 10px;
                margin-left: 8px;
                width: 150px;
            }

            .togglePassword {
                background: none;
                border: none;
                margin-left: 8px;
                cursor: pointer;

                svg {
                    height: 20px;
                }
            }
        }

        .eliminar {
            font-size: 14px;
            margin-top: 16px;
            display: flex;
            align-items: center;
            svg {
                height: 15px;
            }
        }

        svg {
            height: 20px;
        }

        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;

            .header {
                max-width: 60px;

                .contIcono {
                    width: 20px;
                }

                .caracteristicaId {
                    width: 40px;
                }
            }

            .caracteristica {
                padding-left: 0;
                width: auto;

                .passwordContainer {
                    input {
                        margin-left: 0;
                    }
                }
            }

            .eliminar {
                margin: 0;
            }
        }
    }

    .agregarCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;
        border: solid 1px #ccc;
        width: 90%;
        border-radius: 15px;
        padding: 10px 16px;
        font-size: 14px;
        max-width: 900px;

        .iconInputCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
        }

        input {
            border: solid 1px #ccc;
            padding: 8px;
            border-radius: 10px;
            margin-left: 8px;
        }

        button {
            svg {
                height: 15px;
            }
        }

        svg {
            height: 20px;
        }
    }

    @media (min-width: 768px) {
        .titulo {
            font-size: 22px;  // Aumentar el tamaño de la fuente después de 768px
        }

        .descrip {
            font-size: 14px;  // Aumentar el tamaño de la fuente después de 768px
        }

        .subtitulo {
            font-size: 16px;  // Aumentar el tamaño de la fuente después de 768px
        }

        .passwordContainer .passwordInput {
            width: 200px;  // Aumentar el tamaño del input después de 768px
        }

        .staffItem .caracteristicaId span,
        .staffItem .caracteristicaId h3,
        .staffItem .caracteristica span,
        .staffItem .caracteristica h3 {
            font-size: 16px;  // Aumentar el tamaño de la fuente después de 768px
        }

        .agregarCont {
            font-size: 16px;  // Aumentar el tamaño de la fuente después de 768px
        }
    }
}