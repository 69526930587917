// Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.mapContainer {
  width: 90%;
  max-width: 1000px;
  height: 250px; 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 1px 0 10px #bbbbbb;

  svg{
    color: rgb(253, 40, 40);
  }
  @media(min-width: 768px){
    height: 300px;
  }

  .verEnGoogle{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background-color: #cccccc00;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 0;
    border-radius: 0 0 15px 15px;
    height: 40px;

    .googleMapsIcon{
      width: 80px !important;
      margin-left: 4px;
      margin-top: 3px;
      min-width: none !important;
    }

    svg{
      margin-left: 3px;
      width: 10px;
      color: #252525;
    }

    @media(min-width: 768px){
      font-size: 16px;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 800;
  padding: 8px 0;

  @media(min-width: 768px){
    font-size: 18px;
  }
}

.mapHolder {
  width: 100%;  
  height: calc(100% - 40px);
  overflow: hidden;
}


  