.PhoneInput{
    width: 98%;
    margin: 0 auto;
}

.PhoneInputInput {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 40px !important;
    border-radius: 8px !important;
    padding: 10px;
    border: 1px solid #ccc;
    font-family: "Montserrat", -apple-system, sans-serif;
    margin: 0 !important;
    font-size: 14px;
    color: black;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;

    &:focus {
        border-color: #99419d !important;
        box-shadow: 0 0 5px hsl(297, 41%, 20%);
        outline: none;
    }
}

// Estilos para teléfono válido
.valid-phone {
    .PhoneInputInput {
        border-color: green !important;
        
        &:focus {
            box-shadow: 0 0 5px rgba(0, 128, 0, 0.5) !important;
        }
    }
}

// Estilos para teléfono inválido
.invalid-phone {
    .PhoneInputInput {
        border-color: red !important;
        
        &:focus {
            box-shadow: 0 0 5px rgba(255, 0, 0, 0.5) !important;
        }
    }
}

.PhoneInputInput::placeholder {
    color: #ccc;
}

.PhoneInputCountryIconImg{
    display: block !important;
}