.eventoNoDisponible{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70vh;

    img{
        width: 120px;
        margin-top: 25%;
    }
    h1{
        font-size: 20px;
        font-weight: 500;
        color: #f15656;
        width: 80%;
        padding: 0;
        margin-bottom: 25%;
        text-align: center;
    }

    @media(min-width: 768px){
        img{
            width: 200px;
            margin-top: 10%;
        }
        h1{
            font-size: 24px;
            margin-bottom: 7%;
        }
    }
}