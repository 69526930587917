.body {
    min-height: 70vh;
    width: 100%;

    .header {

        .imagenHeader{
            border-radius: 0 !important;
            width: 100%;
            object-fit: cover;

            @media(min-width: 768px) {
                height: 400px;
            }
            @media(min-width: 1024px) {
                height: 500px;
            }
        }
        h1 {
            font-size: 20px;    
            width: 80%;
            text-align: center;
            margin: 0 auto;
            padding: 16px 0;

            @media(min-width: 768px) {
                font-size: 24px;
            }
        }

        h2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            width: 70%;
            text-align: center;
            margin: 0 auto;
            padding: 0 0 16px;

            @media(min-width: 768px) {
                font-size: 20px;
            }
        }
    }

    .eventos {
        transition: all 0.5s;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0px;
        margin-bottom: 50px;
      }

    .contenedorMetodosDePago {
        align-items: center;
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin: 0 auto;
        flex-wrap: nowrap;
    }

    .contenedorMetodosDePago div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        width: 40px;
        border: solid 1px #252525;
        padding: 6px 8px;
        margin: 0 5px;
        border-radius: 5px;
    }

    .contenedorMetodosDePago img {
        padding: 0 5px;
    }

    .contenedorMetodosDePago .mc {
        width: 40px;
        height: 17.5px;
    }

    .contenedorMetodosDePago .mp {
        width: 70px;
        height: auto;
    }

    .contenedorMetodosDePago .visa {
        width: 50px;
        height: 12.5px;
    }

}