.overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1000 !important;
}

.modal {
    background-color: white !important;
    padding: 15px !important;
    border-radius: 8px !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4) !important;
    width: 90% !important;
    max-width: 400px !important;
    text-align: center !important;

    /* Initial state for the scale transition */
    transform: scale(0.8) !important;
    opacity: 0 !important;

    /* Transition effect */
    transition: transform 0.3s ease, opacity 0.3s ease !important;
}

.modal-enter {
    transform: scale(1) !important;
    opacity: 1 !important;
}

.closeButton {
    background-color: #252525 !important;
    color: white !important;
    padding: 8px 16px !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    margin-top: 15px !important;
    transition: background-color 0.3s ease !important;
    font-size: 14px !important;

    &:hover {
        background-color: #1b1b1b !important;
    }
}

.tituloAlert {
    margin-top: 8px !important;
    font-size: 20px !important;
    color: #333 !important;
}

.descripcionAlert {
    font-size: 14px !important;
    color: #666 !important;
    margin-top: 8px !important;
    margin-bottom: 16px;
}

/* Estilos para pantallas más grandes (a partir de 768px) */
@media (min-width: 768px) {
    .modal {
        padding: 20px !important;
        width: 80% !important;
        max-width: 500px !important;
    }

    .closeButton {
        padding: 10px 20px !important;
        font-size: 16px !important;
    }

    .tituloAlert {
        font-size: 24px !important;
    }

    .descripcionAlert {
        font-size: 16px !important;
    }
}