.downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #1d6b41;
    border: none;
    font-family: "Montserrat", -apple-system, sans-serif;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px; 
    width: 150px; 
    font-size: 10px; 
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;

    svg {
        margin-left: 6px;
        width: 16px;
        height: 16px;
    }

    &:hover {
        background-color: #154d2f;
        color: white;
    }

    &:disabled {
        background-color: #c9c9c9;
        cursor: not-allowed;
    }
}

@media (min-width: 768px) {
    .downloadButton {
        padding: 8px;
        width: 200px; 
        font-size: 12px;
        margin-left: auto;
        svg {
            margin-left: 8px;
            width: 20px;
            height: 20px;
        }
    }
}
