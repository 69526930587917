.container {
    width: 100%;
    height: 100%;
    padding: 40px 24px;
    background: linear-gradient(45deg, #E380E9, #65126A);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
}
.error {
    color: red;
    font-size: 14px;
    margin-top: -10px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 39px;
    width: 100%;
}

.textContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.question {
    align-self: stretch;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.subheading {
    align-self: stretch;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.error {
    color: rgb(255, 90, 90);
    font-size: 12px;
    margin-top: -10px;
    font-weight: 600;
    text-shadow: 0 0 10px rgba(99, 99, 99, 0.318);

    @media (min-width: 768px) {
        font-size: 14px;
    }
}

.input {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    height: 40px;
    font-size: 14px;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.textarea {
    width: 100%;
    height: 100px;
    padding: 12px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-family: inherit;
    resize: none;

    @media (min-width: 768px) {
        font-size: 16px;
    }
}

.sendMessageButton {
    background-color: #252525;
    color: #ffffff;
    border-radius: 8px;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sendMessageButton:hover {
    transform: scale(1.05);
}


@media (min-width: 768px) {
    .container {
        padding: 74px 224px;
    }

    .content {
        width: 50%;
    }
}

.successContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;

    h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 16px 0 8px;
    }

    .successIcon {
        font-size: 80px;
        color: rgb(112, 217, 121);
    }

    .successMessage {
        font-size: 16px;
        color: white !important;
        font-weight: 400;
        line-height: 1.4;
    }
}