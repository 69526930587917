/* Estilos básicos para rrppItem */
.rrppItem {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  height: 58px;
  animation: loading 1.5s infinite;
  border-radius: 15px;
}

@keyframes loading {
  0% {
    background-color: #eeeeee;
  }

  50% {
    background-color: #e0e0e0;
  }

  100% {
    background-color: #eeeeee;
  }
}