.cardEvento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    border-radius: 15px;
    width: 300px;
    padding: 7px;
    margin: 30px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: ease-out 0.5s;

    .contEncabezados {
        height: 40px;
        border-bottom: solid 1px #ccc;
        margin: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        color: #ccc;
        margin: 0;
        padding: 0px;
        font-weight: 900;
    }

    @media (min-width: 500px) {
        padding: 7px;
        margin: 10px 10px;
    }
    @media (min-width: 768px) {  
        margin: 20px;
    }
   
}

.cardEventoImgLink,
.cardEventoImg {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 15px;
    margin: 0 0 16px 0;
    width: 240px;
}

.contenidoEvento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.eventDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
}

.eventTitle,
.atributoCard,
.botonComprar {
    width: 95%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
}

.botonComprar {
    background-color: #99419d;
    color: #ffffff;
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    text-decoration: none;
    position: absolute;
    bottom: 0;
    left: 0;
}

/* Estilos para el esqueleto */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}