//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

* {
  font-family: $tipografia;
}

.ticketConsumicionCont {
  background-color: #fff;
  border-radius: 10px;
  max-width: 300px;
  margin: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

  .ticketConsumicionHeader {
    background-color: #99419d;

    padding: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .ticketConsumicionTitulo {
      font-size: 18px;
      color: #ffffff;
      width: 100%;
      font-weight: 700;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      text-align: center;
      justify-content: center;
      .ticketConsumicionImg {
        width: 10px;
        margin-left: 4px;
      }
    }
  }

  img {
    vertical-align: middle !important;
  }

  .ticketConsumicionBrand {
    text-align: center;
    margin: 4px auto;

    .brandName {
      font-size: 18px;
      color: #99419d;
      font-weight: 900;

      .brandAccent {
        color: #c970ce;
      }
    }
  }

  .ticketConsumicionQR {
    text-align: center;
    padding: 0 16px;

    .qrCodeImg {
      max-width: 100%;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
      height: auto;
      border-radius: 5px;
    }
  }

  .ticketConsumicionId {
    text-align: center;
    padding: 8px 0;
    background-color: #e5e7eb;
    border-radius: 8px;
    margin: 16px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }

  .ticketConsumicionProducto {
    text-align: center;
    padding: 0;
    font-size: 22px;
    color: #000;
    font-weight: 700;
  }

  .ticketConsumicionInfo {
    padding: 0 16px;
    text-align: center;
    padding-bottom: 4px;
    font-size: 12px;
    color: #6b7280;
  }

  .btnDescargarIndividual {
    width: 200px;
    position: relative;
    height: 40px;
    margin: 0 auto 10px;
    font-size: 14px;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    border: none;
    background-color: #99419d;
    color: #ffffff;
    padding: 8px;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    border-left: 1px solid #99419d;
    border-right: 1px solid #99419d;
  }
}
