.cajasContainer {
    background: linear-gradient(100deg, #E380E9 0.71%, #65126A 97.21%);
    min-height: 70vh;
    width: 100%;
    padding: 0 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titulo {
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 90%;
    font-weight: 800;
    margin-bottom: 32px;

    @media(min-width: 768px) {
        font-size: 22px;
    }
}

.header{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
    justify-content: flex-start;
    margin: 16px 0 auto 0;

    .volver{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        color: #fff;
        padding: 0 8px;
        margin: -10px 4px 0;
        font-size: 14px;

        @media(min-width: 768px){
            font-size: 18px;
        }

        path{
            width: 40px;
        }
    }
}

.contCajasYAgregar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: auto;


    @media(min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.caja {
    padding: 16px;
    margin: 16px 0;
    border: 1px solid #fff; // Agregar borde de 1px blanco al cuadro de la caja
    border-radius: 8px; // Opcional, para que las esquinas sean redondeadas

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    p {
        font-weight: 700;
        color: white;
    }

    @media(min-width: 768px) {
        margin: 0 16px;
    }
}

.buttonSubmit {
    border: solid 1px #fff;
}

.contIconCaja {
    padding: 16px;
    border-radius: 50%;
    background-image: linear-gradient(75deg, #dddddd, #fff, #dddddd);
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    .icon {
        font-size: 50px;
        color: #99419d;
    }
}

.conIconSkeleton {
    padding: 16px;
    border-radius: 50%;
    background-color: #e0e0e0;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;

    .icon {
        font-size: 50px;
        color: #ccc;
    }
}

.conIconSkeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, #eeeeee 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      left: -150%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 150%;
    }
  }

.noCajas {
    margin-bottom: 20px;
    font-size: 16px;
    color: #fff;
}

.listaCajas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    width: auto;

    @media(min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.newCajaForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    border: 1px solid #fff; // Borde de 1px blanco
    border-radius: 8px; // Opcional, para esquinas redondeadas
    padding: 16px; // Aumentar el margen interior para consistencia
    position: relative; // Necesario para posicionar el menú desplegable

    @media(min-width: 768px) {
        margin: 0 16px;
    }

    .menuIcon {
        position: absolute;
        top: 8px; // Ajusta según tu diseño
        right: 8px; // Ajusta según tu diseño
        font-size: 1.5rem;
        cursor: pointer;
        color: #fff;
    }

    .menu {
        position: absolute;
        top: 36px;
        right: 8px;
        background-color: #fff;
        color: #000;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 10;
        display: none;

        &.active {
            display: block;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;

            input {
                margin-right: 8px;
            }
        }
    }
}
.importarCaja {
    margin: 10px 0;
    width: 100%;

    .selectorCaja {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
    }
}


.inputCaja {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.importarCajaTexto {
    font-size: 12px;
    color: #fff;
    margin: 0 0 4px 0;
}

.addCaja {
    cursor: pointer;
    margin-top: 20px;
    align-self: center;

    @media(min-width: 768px) {
        margin: 0 0 0 16px;
    }
}

.addIcon {
    font-size: 3rem;
    color: #fff;
}
