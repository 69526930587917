.infoGoogle {
    width: 100%;
    padding: 2rem 0;
    background-color: #ffffff;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .comment {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin: 0;
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .image {
    max-width: 150px;
    max-height: 70px;
    height: auto;
    object-fit: contain;
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.1);
    }
  }
  
  // Media queries para responsividad
  @media (max-width: 768px) {
    .imageContainer {
      gap: 1.5rem;
    }
  
    .image {
      max-width: 120px;
    }
  
    .comment {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .imageContainer {
      gap: 1rem;
    }
  
    .image {
      max-width: 100px;
    }
  
    .comment {
      font-size: 1.2rem;
    }
  }