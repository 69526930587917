.contenedorTestimonios {
  background-image: linear-gradient(180deg, #512049, #752e69);
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-top: 60px;

  @media(min-width: 768px) {
    padding: 60px 10%;
  }

  .titulo {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 16px;

    @media(min-width: 768px) {
      font-size: 32px;
      margin-bottom: 16px;
    }
  }

  .slider {
    overflow: hidden;
    width: 100%;
    padding: 20px 0;
    gap: 15px;
    position: relative;

    .testimonios {
      display: flex;
      transition: transform 0.5s ease-in-out;

      @media (min-width: 768px) {
        transform: translateX(0) !important; 
      }

      .testimonioItem {
        background-color: #fff;
        border-radius: 10px;
        min-width: 100%;
        box-sizing: border-box;
        padding: 20px;
        text-align: left;

        @media (min-width: 768px) {
          min-width: calc(33.33% - 20px);
          margin: 0 10px;
        }

        .header {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: flex-start;

          .imagen img {
            width: 40px;
            min-width: 40px;
            height: 40px;
            object-position: center;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 6px;
          }

          .nombreYUbicacion{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;

            h4{
              font-size: 16px;
              font-weight: 700;
            }

            p{
              font-weight: 400;
            }
          }
        }

        .descripcion{
          font-size: 12px;
          font-weight: 500;
          color: #1b1b1b;

          @media(min-width: 768px){
            font-size: 16px;
          }
        }
      }
    }
  }

  .dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ccc;
      margin: 0 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &.active {
        background-color: #333;
      }
    }

    @media (min-width: 768px) {
      display: none; 
    }
  }

  .navigation {
    display: none; 

    @media (min-width: 768px) {
      display: none; 
    }
  }
}