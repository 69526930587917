// Variables
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    background: linear-gradient(100deg, #E380E9 0.71%, #65126A 97.21%);
    width: 100%;
    min-height: 100vh;
    padding: 32px 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;

        .volver {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            color: #fff;
            padding: 0 8px;
            margin: -10px 4px 0;
            font-size: 14px;
            background: none;
            border: none;
            cursor: pointer;

            @media(min-width: 768px) {
                font-size: 18px;
            }
        }
    }
}

.crearMenuContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $tipografia;

    h2 {
        color: #fff;
        font-size: 24px;
        font-weight: 800;
        line-height: 1.2;
        margin: 0 0 16px;
        text-align: center;
        width: 100%;
    }

    .fondoDescripcion {
        width: 100%;
        background-color: hsla(0,0%,80%,.475);
        border-radius: 15px;
        padding: 16px;
        margin-bottom: 32px;
        text-align: center;

        p {
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            line-height: 1.5;
        }
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .productos {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
        margin-bottom: 40px;
    }

    .producto {
        width: calc(33.33% - 16px);
        min-width: 280px;
        max-width: 320px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        padding: 20px 20px 60px;
        transition: transform 0.2s ease;
        position: relative;

        &:hover {
            transform: translateY(-2px);
        }

        label {
            font-size: 14px;
            color: $colorDeFuente2;
            margin-bottom: 8px;
            width: 100%;
            text-align: left;
        }

        .productImage {
            width: 100%;
            background-color: #f8f8f8;
            border-radius: 8px;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                border-radius: 8px;
                border: solid 1px #ccc;
            }

            .editIcon {
                position: absolute;
                top: 10px;
                right: 5px;
                z-index: 10;
                filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.5));
                color: #fff;
                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                    color: #eeeeee;
                }
            }
        }

        .inputProducto {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            padding: 8px;
            margin-bottom: 12px;
            border: 1px solid #ccc;
            font-size: 14px;

            &:focus {
                outline: none;
                border-color: $violeta;
                box-shadow: 0 0 0 2px rgba($violeta, 0.2);
            }
        }

        .deleteBtn {
            position: absolute;
            bottom: 16px;
            right: 16px;
            background: transparent;
            border: 1px solid rgb(255, 92, 93);
            border-radius: 8px;
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 8px 12px;
            gap: 8px;
            color: rgb(255, 92, 93);
            font-size: 14px;
            transition: all 0.3s ease;
            opacity: 1;

            &:hover {
                background: rgb(255, 92, 93);
                color: white;
                transform: translateY(-2px);
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .agregarBtn {
        width: calc(33.33% - 16px);
        min-width: 280px;
        max-width: 320px;
        height: auto;
        aspect-ratio: 1;
        font-size: 16px;
        border: 2px solid #fff;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 24px;
        border-radius: 12px;
        background-color: transparent;
        color: #fff;
        transition: all 0.3s ease;
        align-items: center;
        justify-content: center;

        &:hover:not(:disabled) {
            background-color: $violeta;
            border-color: #ccc;
            transform: translateY(-2px);
        }

        svg {
            width: 50px;
            height: 50px;
        }

        @media(max-width: 768px) {
            width: 100%;
            aspect-ratio: auto;
            height: auto;
            padding: 16px;
            flex-direction: row;
            justify-content: center;
        }
    }

    .submitButton {
        background-color: $violeta;
        color: #fff;
        margin-top: 16px;
        width: 100%;
        max-width: 320px;
        padding: 16px 32px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 12px;
        
        &:hover:not(:disabled) {
            background-color: $violetaOscuro;
            transform: translateY(-2px);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }
}

// Media queries para responsividad
@media (max-width: 1200px) {
    .crearMenuContainer .producto {
        width: calc(50% - 12px);
    }
}

@media (max-width: 768px) {
    .crearMenuContainer {
        .producto {
            width: 100%;
            max-width: none;
        }

        h2 {
            font-size: 20px;
        }

        .fondoDescripcion p {
            font-size: 14px;
        }
    }
}
  