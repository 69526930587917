//Colores de la empresa
$violeta: rgb(154, 66, 158);
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f700;
    padding-bottom: 50px;
    width: 100%;

    .InformeVentasH1{
        width: 90%;
        max-width: 900px;
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        color: #1b1b1b;
        padding: 16px 0;
        margin-bottom: 8px;

        span{
            font-size: 20px;
            font-weight: 400;
            line-height: 90%;
        }

    }

    .brtopleft{
        border-radius: 5px 0 0 0!important;
    }
    .brtopright{
        border-radius: 0 5px 0 0!important;
    }
    .brbottomright{
        border-radius: 0 0 5px 0!important;
    }
    .brbottomleft{
        border-radius: 0 0 0 5px!important;
    }

    .seccionCards{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-around;
        width: 90%;
        max-width: 900px;
        margin-bottom: 22px;

        .cardVentas{
            width: 30%;
            color: #1b1b1b;
            border-radius: 15px;
            box-shadow: 1px 0 20px #bbbbbb;
            padding: 8px;

            h5{
                font-weight: 500;
                font-size: 10px;
            }
            p{
                margin: 8px 0;
                font-size: 12px;
                word-break: break-all;
                font-weight: 600;
            }

            @media(min-width: 768px){
                padding: 16px;
                h5{
                    font-size: 12px;
                }
                p{
                    font-size: 16px;
                }
            }
        }

        .cardVentasTotales{
            background-color: #252525 ;
            color: #f7f7f7;
        }
        .cardVentaALiquidar{
            background-color:#d1ffd1;
        }
        .cardVentaComisiones{
            background-color: #f5daff;
        }
    }

    .aclaracion{
        font-size: 10px;
        width: 80%;
        max-width: 800px;  
        text-align: center; 
        
        @media(min-width: 768px){
            font-size: 12px;
        }
    }

    .seccionDeInforme{
        width: 90%;
        max-width: 900px;

        h2 {
            font-size: 18px;
            text-align: left;
            margin-top: 32px;
            width: 100%;
            border-bottom: solid 1px #ccc;
            margin-bottom: 8px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 10px;
            box-shadow: 1px 0 20px #bbbbbb;

            th{
                text-align: left;
                background-color: $violetaOscuro;
                color: #fff;
                padding: 4px;
                font-size: 14px;
                font-weight: 400;
            }
            td{
                text-align: left;
                font-weight: 500;
                font-size: 12px;
                background-color: #fff;
                padding: 4px;
            }
            b{
                font-weight: 600;
            }
    
            @media(min-width: 768px){
                th{
                    font-size: 16px;
                }
                td{
                    font-size: 14px;
                }
            }
        }
    }
}