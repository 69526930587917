//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.nosotrosBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    li {
        text-align: left;
        
    }

    img{
        width: 80%;
        max-width: 200px;
    }

    .subtitulo{
        font-size: 22px;
        font-weight: 600;
        margin-top: 32px;
    }
}

.nosotrosBody .nosotrosCuerpo {
    width: 80%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titulo {
    font-family: "Montserrat";
    text-align: center;
    color: #99419d;
    float: left;
    padding: 15px;
    margin-top: 30px !important;
    font-size: 24px !important;
    margin: 0;
    font-weight: 900 !important;
}

.nosotrosBody .nosotrosCuerpo h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 1.2;
}

.nosotrosBody h2 {
    margin-top: 20px;
    font-size: 24px;
    width: 100%;
    text-align: left;
    border-bottom: solid 1px #ccc;
    height: auto;
}



.nosotrosBody span{
    margin: 20px 0 10px;
    font-weight: 600;
}

// ParentComponent.module.scss
@import '../../Components/Buttons-loaders-inputs/WhatsAppButton/WhatsAppButton.module.scss';

.nosotrosBody .nosotrosCuerpo .whatsAppButton {
  background-color: #123456;
}
