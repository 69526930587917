.envioQrForm {
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 5vh auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;
  h2 {
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 8px;
      span {
          font-weight: 400;
      }
  }
  .subtitulo {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 16px;
      color: #1B1B1B;
      span {
          font-weight: 400;
      }
  }
  p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background-color: #cccccc79;
      border-radius: 10px;
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      color: #5C5C5C;
  }
  .formulario {
      width: 100%;
  }
  .inputGroup {
      width: 100%;
      margin-bottom: 16px;
      .radioGroup {
          display: flex;
          flex-direction: column;
          gap: 8px;
          label {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              color: #5C5C5C;
              cursor: pointer;
              input[type="radio"] {
                  appearance: none;
                  width: 16px;
                  height: 16px;
                  border: 1px solid #99419D;
                  border-radius: 50%;
                  outline: none;
                  transition: border-color 0.2s ease-in-out;
                  &:checked {
                      border-color: #99419D;
                      background-color: #99419D;
                  }
              }
          }
      }
      .label {
          display: block;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 600;
          color: #1B1B1B !important;
      }
      .input {
          width: 100%;
          height: 40px;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 14px;
          color: #1B1B1B;
          outline: none;
          transition: border-color 0.2s ease-in-out;
          &:focus {
              border-color: #99419D;
          }
          &::placeholder {
              color: #999;
          }
      }
  }
  .boton {
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #99419D;
      border-radius: 5px;
      cursor: pointer;
      padding: 4px;
      width: 40px !important;
      height: 45px !important;
      color: #99419D;
      font-size: 20px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
          width: 24px;
      }
  }
  .cantidadControles {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      padding: 8px;
      margin: 0 auto !important;
  }
  input {
      height: 42px;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      border: 1px solid #ccc;
      font-size: 14px;
  }
  .btnCantidad {
      background-color: #ffffff00;
      border: 1px solid #99419D;
      border-radius: 5px;
      cursor: pointer;
      padding: 4px 8px;
      color: #99419D;
  }
  .detalleTipoTicket {
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
  }
  input[type="number"] {
      width: 40px;
      height: 40px;
      border: 1px solid #99419D;
      border-radius: 5px;
      text-align: center !important;
      font-family: "Montserrat", sans-serif;
      color: #99419D;
      font-size: 22px;
      background-color: #ffffff00;
      margin: 5px;
      outline: none;
      appearance: none;
      line-height: normal;
      -moz-appearance: textfield;
      padding-inline: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  @media (min-width: 768px) {
      input[type="number"] {
          width: 45px;
      }
      h4 {
          font-size: 18px;
      }
  }
  @media(min-width: 768px) {
      margin: 10vh auto;
  }
}