.footerContainer {
  width: 100%;
  height: 100%;
  padding: 60px 5%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    text-align: left;
  }
}

.logoContainer {
  position: relative;
  font-weight: 900;
  font-size: 24.73px; 
  line-height: 32.15px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    justify-content: center;
  }
}

.logoSimple {
  color: #99419D;
}

.logoPass {
  color: #C970CE;
  margin-left: 0;
}

.linea {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 16px auto;

  @media (max-width: 768px) {
    margin: 32px auto;
  }
}

.linkContainer {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
}

.link {
  color: #161C2D;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap-reverse;

  span {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row-reverse;

    .socialMediaContainer {
      margin-bottom: 10px;
    }
  }
}

.socialMediaContainer {
  display: flex;
  gap: 50px;
  margin-bottom: 200;

  @media (max-width: 768px) {
    gap: 30px;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
  }
}

.socialMediaContainer svg {
  width: 19px;
  height: 19px;
  opacity: 0.4;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
}

.socialMediaContainer svg:hover {
  opacity: 1;
}

.copyright {
  flex-basis: 100%;
  color: #161C2D;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 20px;
  }
}

.simple {
  color: #99419D;
  font-size: 24.73px;
  font-weight: 900;
  line-height: 32.15px;
  word-wrap: break-word;
}

.pass {
  color: #C970CE;
  font-size: 24.73px;
  font-weight: 900;
  line-height: 32.15px;
  word-wrap: break-word;
}

.quienesSomos {
  color: #161C2D;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
}

.footerLink {
  color: #161C2D;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
}

.copyrightText {
  color: #161C2D;
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  word-wrap: break-word;
}

.separator {
  width: 327px;
  height: 2px;
  border: 1px #E7E9ED solid;
}