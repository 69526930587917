.bodyReset{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    
}

.resetForm{
    width: 90%;
    max-width: 800px;
    padding: 20px;
    background-color: rgba(238, 238, 238, 0.1019607843);
    border-radius: 15px;
    box-shadow: 1px 0 20px #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form{
        width: 100%;
    }

    p{
        width: 100%;
        text-align: left;
    }
    h2 {
        color: #1b1b1b;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
    }
    svg{
        font-size: 20px;
        height: 20px;
    }

    input {
        height: 40px;
        width: 100%;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        font-size: 14px;
    }


}

