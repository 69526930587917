.bodyGR {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    .titulo {
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
        width: 90%;
        max-width: 900px;
        padding-left: 8px;
    }

    .descrip {
        font-size: 12px;
        background-color: #cccccc79;
        padding: 8px;
        color: #1b1b1b;
        width: 90%;
        border-radius: 15px;
        max-width: 900px;
    }

    .subtitulo {
        border-bottom: solid 1px #ccc;
        padding-bottom: 3px;
        width: 90%;
        max-width: 900px;
        margin: 16px 0 8px;
    }

    .listaRrpps {
        width: 90%;
        max-width: 900px;
        margin-top: 20px;
    }

    .rrppItemAsignado {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: solid 1px #ccc;
        border-radius: 10px;
        margin: 0 auto 16px;
        width: 100%;

        .contNombreAsignado{
            font-size: 16px;
            width: 90%;
            margin: 8px auto 0;
            text-align: left;
            font-weight: 800;
            display: flex;

            span{
                margin-left: 4px;
            }
        }

        .contDatos {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 90%;

            p{
                margin-top: 8px;
                font-weight: 600;

                span{
                    font-weight: 400;
                }
            }
        }

        .botonDesasignar{
            font-size: 12px;
            padding: 8px;
            color: white;
            border-radius: 10px;
            margin: 16px auto 8px;
            background-color: rgb(251, 69, 69);
            display: flex;
        }
    }

    .rrppItem {
        width: 100%;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0;
        border: solid 1px #ccc;
        border-radius: 15px;

        .contNombre {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 8px;
            }
        }

        svg {
            height: 20px;
        }

        button {
            font-size: 14px;

            svg {
                height: 15px;
            }
        }

    }

    .agregarCont {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: solid 1px #ccc;
        width: 90%;
        border-radius: 15px;
        padding: 10px 16px;
        max-width: 900px;
        flex-wrap: wrap;

        .iconInputCont {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
        }

        input {
            border: solid 1px #ccc;
            padding: 8px;
            border-radius: 10px;
            margin-left: 8px;
        }

        svg {
            height: 20px;
        }
    }

    .botonAsignar {
        padding: 8px;
        border: solid 1px #ccc;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }

    @media(min-width: 768px) {

        .titulo {
            font-size: 22px;
        }

        .descrip {
            font-size: 14px;
        }
    }
}