//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.trabajaConNosotros {
  width: 90%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5vh auto;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  min-height: 70vh;

  p{
    align-self: center;
  }

  h1 {
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
    width: 100%;
  }

  h2 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 24px;
    width: 100%;

    .card {
      background: linear-gradient(to left, $violetaOscuro, lighten($violetaOscuro, 15%));
      border-radius: 8px;
      color: #fff;
      padding: 16px;
      width: calc(50% - 10px);
      margin: 5px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .formulario {
    width: 100%;
  }

  .inputGroup {
    width: 100%;
    margin-bottom: 16px;
  }

  .contEmail{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 16px;

    h3{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    h4{
      font-size: 16px;
      font-weight: 600;
      border: solid 1px #ccc;
      border-radius: 10px;
      padding: 16px;
      width: 100%;
    }
  }

  @media(min-width: 768px) {

    h1{
      font-size: 30px;
    }

    p{
      font-size: 18px;
    }

    .cardsContainer{
      .card{
        font-size: 18px;
      }
    }

    h2{
      font-size: 22px;
    }
    .cardsContainer {
      min-height: 100px;
      padding: 0 30px 30px 30px;
    }
  }
}