$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 80vh;
  padding: 40px 20px !important;
  background-color: #f7f7f7;
}

.panelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 70vh;
  border-radius: 10px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
}

.headerRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.title {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    font-size: 32px;
  }
}

.contenedortabla {
  width: 100%;
  overflow-x: auto;
  max-width: 1200px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;

  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #ddd;

    @media (max-width: 767px) {
      padding: 8px;
      font-size: 12px;
    }
  }

  th {
    background-color: $violetaOscuro;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;

    @media (max-width: 767px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  td {
    font-size: 14px;
    color: $colorDeFuente;

    @media (max-width: 767px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 15px;
    }
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
}

.error {
  color: #f44336;
  text-align: center;
  margin: 20px 0;
  font-weight: 500;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;

  button {
    background-color: $violeta;
    color: white;
    border: none;
    padding: 10px 20px;
    font-family: $tipografia;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s;

    &:hover:not(:disabled) {
      background-color: $violetaOscuro;
      transform: scale(1.05);
    }

    &:disabled {
      background-color: $colorDeFuente2;
      cursor: not-allowed;
      opacity: 0.7;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  span {
    font-family: $tipografia;
    font-size: 14px;
    color: $colorDeFuente;
    margin: 0 10px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}

.estadoIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    
    &.success {
      background-color: #4CAF50;
      box-shadow: 0 0 4px rgba(76, 175, 80, 0.5);
    }
    
    &.error {
      background-color: #f44336;
      box-shadow: 0 0 4px rgba(244, 67, 54, 0.5);
    }
  }
}

.verDetalleButton {
  background-color: $violeta;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
  font-family: $tipografia;
  white-space: nowrap;

  &:hover {
    background-color: $violetaOscuro;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767px) {
    padding: 6px 12px;
    font-size: 12px;
  }
}

// Estilos responsivos adicionales para móviles
@media (max-width: 767px) {
  .contenedortabla {
    margin: 0 -20px;
    border-radius: 0;
  }

  .table {
    th, td {
      &:nth-child(2),//tipo
      &:nth-child(4),//cae
      &:nth-child(5),

      &:nth-child(7)
       {
        display: none; // Oculta las columnas CAE y Collection ID en móvil
      }
    }
  }
} 