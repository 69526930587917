$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.contGrafico {
    width: 90%!important;
    max-width: 400px!important;
    height: 250px;
    color: #99419d;
    aspect-ratio: 1 / 1;
    max-height: 300px!important;
    background-color: #ffffff;
    box-shadow: 1px 0 20px #bbbbbb;
    border-radius: 10px;
    margin: 20px 0 0;

    h3 {
        padding: 8px 0 0 8px;
    }
    
    span {
        font-size: 12px;
        color: #bbbbbb;
    }
    
    .graph {
        width: 100%!important;
    }

    path {
        color: $violetaOscuro!important;
    }

    @media(min-width: 500px) {
       width: 40%!important;
       margin: 10px;
    }

    @media(min-width: 768px) {
        margin: 20px;
        height: auto!important;
    }
} 