.btWhatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 60px;
    width: 60px;
    background-color: #25d366;
    border-radius: 100%;
    font-size: 40px;
    color: #fff;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    transition: all 0.5s;
    animation: float 3s ease-in-out infinite;
  
    // Definición de la animación
    @keyframes float {
      0% {
        transform: translateY(0); 
      }
      50% {
        transform: translateY(-5px); 
      }
      100% {
        transform: translateY(0); 
      }
    }
  }