// Trans.module.scss
.pageTransitionEnter {
    opacity: 0;
  }
  
  .pageTransitionEnterActive {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  
  .pageTransitionExit {
    opacity: 1;
  }
  
  .pageTransitionExitActive {
    opacity: 0;
    transition: opacity 300ms ease-in;
    background-color: aqua;
  }
  