.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #ffffff;
    padding: 0;
    border-radius: 15px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #862f8b; /* Use color from DetallesUsuarioModal */
    color: white;
    padding: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modalTitle {
    font-size: 20px;
    font-weight: 600;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.modalBody {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    
    p{
        width: 90%;
        font-size: 14px;
        font-weight: 600;
    }
}

.input {
    width: 90%;
    padding: 10px;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
}

.modalFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 8px;
}

.cancelButton {
    background: #f44336;
    color: white;
    border: none;
    height: 40px;
    font-size: 12px;
    padding: 0 16px;
    border-radius: 8px;
    cursor: pointer;
}