.button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 230px;
    height: 40px;
    font-weight: 400;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    background-image: linear-gradient(-45deg,#0099db,#00b3ff,#0099da) !important;
    color: white;
    border: none;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    img {
      margin-right: 4px;
    }
  }