.timerAlert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #415D9D;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    z-index: 9999;
}
