.principalContainer {
    position: relative;
    width: 100%;
    padding: 80px 25px; 
    background: linear-gradient(1deg, white 40%, #F6F6F6 100%);
    overflow: hidden;
  }
  
  .blurElement1, .blurElement2, .blurElement3, .blurElement4 {
    width: 250px; 
    height: 250px; 
    position: absolute;
    border-radius: 9999px;
    filter: blur(100px); 
  }
  
  .blurElement1 {
    left: 300px;
    top: 400px;
    background: rgba(226, 226, 226, 0.90);
  }
  
  .blurElement2 {
    left: 100px;
    top: 500px;
    background: rgba(226, 226, 226, 0.90);
  }
  
  .blurElement3 {
    left: -50px;
    top: 300px;
    background: rgba(255, 255, 255, 0.70);
    filter: blur(100px);
  }
  
  .blurElement4 {
    left: 400px;
    top: 300px;
    background: rgba(255, 255, 255, 0.70);
    filter: blur(100px);
  }
  
  .mainContent {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%; 
    margin: 0 auto;
  }
  
  .textContainer {
    margin-bottom: 30px;
    padding: 25px 0; 
  
    h2 {
      font-size: 16px; 
      font-weight: 500;
      line-height: 26px;
      word-wrap: break-word;
      text-align: center;
      margin: 10px;
      border-bottom: none; 
    }
  
    h1 {
      color: #99419D;
      font-size: 28px; 
      font-weight: 700;
      line-height: 33.6px;
      word-wrap: break-word;
      margin-bottom: 20px;
      text-align: center;
    }
  
    p {
      color: #5C5C5C;
      font-size: 14px; 
      font-weight: 400;
      line-height: 22px;
      word-wrap: break-word;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  
  .poweredBy {
    color: #868686;
    font-size: 12px; 
    font-style: italic;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word;
  
    strong {
      font-weight: 700;
    }
  }
  
  .imageContainer {
    background: #94A2B6;
    box-shadow: 0px 42px 44px -10px rgba(1, 23, 48, 0.12);
    border-radius: 10px;
  }
  
  .mainImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  @media (min-width: 768px) {
  .principalContainer {
    padding: 50px 0; 
  }

  .blurElement1, .blurElement2, .blurElement3, .blurElement4 {
    width: 401px;
    height: 401px;
    filter: blur(200px); 
  }

  .mainContent {
    max-width: 900px; 
  }

  .textContainer {
    margin-bottom: 40px;

    h2 {
      font-size: 20px; 
      line-height: 26px;
    }

    h1 {
      font-size: 48px; 
      line-height: 57.6px;
    }

    p {
      font-size: 18px; 
      line-height: 26px;
    }
  }

  .poweredBy {
    font-size: 16px; 
    line-height: 26px;
  }
}
