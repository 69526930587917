//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

.cbuForm {
    width: 100%;
    padding: 10px;
    background-color: #eeeeee1a;
    border-radius: 15px;
    border: solid 1px #ccc;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    h3 {
      font-family: $tipografia;
      color: $colorDeFuente;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }
  
    input {
      height: 40px;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      font-size: 14px;
  
      ::placeholder{
        font-size: 14px;
      }
    }
  
    small{
      margin-top: 10px;
    }
    @media (min-width: 768px) {
      box-shadow: none;
    }
  
    @media (min-width: 1024px){
      max-height: 504px;
    }
  
  }