//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.perfilUsuario {
  display: flex;
  flex-direction: column;
  align-items: center;justify-content: center;
  width: 100%;
  text-align: center;
  min-height: 60vh;
  font-size: 16px;
  padding: 30px 5% 50px;

  h1 {
    color: #99419d;
    font-size: 24px;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .actionUser {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .perfilAccion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee1a;
    color: #000;
    width: 45%;
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    text-decoration: none;
    box-shadow: 1px 0 20px #bbbbbb;
    margin: 10px 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #99419d;
      color: #ffffff;
      transform: scale(1.02);
    }
    &:active {
      transform: scale(0.95);
    }
  }

  svg {
    font-size: 24px;
    margin-bottom: 4px;
  }

  @media (min-width: 768px) {
    width: 80%;
    max-width: 1000px;
    margin: 50px auto;

    .perfilAccion {
      width: 15%;
      height: 150px;
    }

    .actionUser {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

.actionUserCenter {
  justify-content: center !important;
}
