// Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $tipografia;
} 

.bodyAutenticador {
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  position: relative;
  padding: 30px 0 10px;
  

  h4 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .buttons {
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0s 0.5s;
    visibility: hidden;
  }
  
  .visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } 
  
  .novisible{
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0s 0.5s;
    visibility: hidden;
    display: none !important;
  }

  .contenedorFormulario {
    transition: opacity 0.5s ease, max-height 0.5s ease;
    max-height: 1000px;  
    opacity: 1;          
    overflow: hidden;
    width: 100%;
    padding: 0 20px;
  }
  
  .contenedorFormularioVisible {
    max-height: 1000px;  
    opacity: 1;
  }
}

.btnClose {
  background-color: transparent;
  color: $violeta; 
  border: 2px solid $violeta; 
  border-radius: 8px;
  padding: 8px 20px;
  margin-top: 20px; 
  cursor: pointer;
  font-family: $tipografia;
  transition: all 0.3s ease;

  &:hover {
    background-color: $violeta;
    color: white;
  }
}

.lineaDivisora {
  border-top: 1px solid #cdcdcd;
  width: 90%;
  height: 1px;
  margin: 10px auto; 
}

.cuerpo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnIngresar,
.btnCrearCuenta {
  font-size: 16px;
  font-weight: 500;
  color: #99419d;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 250px;
  border-radius: 8px;
  margin: 10px;

  svg {
    margin-left: 5px;
  }

  &:hover {
    color: white;
  }
}

.btnIngresar {
  background-color: transparent;
  border: solid 2px $violeta;

  &:hover {
    background-color: $violeta;
  }
}

.btnCrearCuenta {
  background-color: transparent;
  border: solid 2px $violeta;

  &:hover {
    background-color: $violeta;
  }
}

.formulario {
  transition: opacity 1.7s ease, max-height 1.7s ease; 
  transform: translateY(30px);
  opacity: 0;
  display: block;  
  visibility: hidden; 
  max-height: 0;
}

.formularioVisible {
  opacity: 1;
  visibility: visible;
  max-height: 1100px;  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;  
}