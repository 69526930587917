* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Montserrat', -apple-system, sans-serif;

  body {
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Montserrat', -apple-system, sans-serif;
    padding: 0 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: inherit !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  img {
    display: inline-block !important;
  }
}