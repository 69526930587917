// Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  font-family: $tipografia;
}

.boton-ir-a-eventos {
  padding: 10px 20px;
  background-color: $violeta;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $violetaOscuro;
  }

  &:active {
    transform: scale(0.95);
  }
}

.cuerpoTickets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #00000000;
  padding: 30px 0;
  min-height: 70vh;

  .infoTickets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;

    .tituloEntradas {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 20px;
      color: $colorDeFuente;
      padding-left: 10px;
      display: flex;
      gap: 5px;
    }

    .fondoSub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      background-color: #cccccc79;
      border-radius: 15px;
      width: 100%;
      max-width: none;

      p {
        font-size: 14px;
        text-align: left;
        font-weight: 400;
        color: $colorDeFuente2;
      }
    }
  }

  .filtroContainer {
    margin-top: 5px;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;

    h5 {
      font-size: 14px;
      font-weight: bold;
      color: $colorDeFuente;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .botonesFiltro {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;      
      gap: 8px;
      justify-content: left;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        padding: 8px;
        border: 2px solid $violeta;
        background-color: white;
        color: $violeta;
        border-radius: 8px;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $violeta;
          color: white;
        }

        &.activo {
          background-color: $violeta;
          color: white;
        }

        svg {
          font-size: 14px;
        }
      }
    }

    @media (min-width: 768px) {

      h5{
        font-size: 16px;
      }
      .botonesFiltro {
        button {
          font-size: 12px !important;
        }
      }

    }
  }

  .lineaDivisora {
    border-top: 1px solid #cdcdcd;
    box-sizing: border-box;
    width: 90%;
    height: 1px;
    margin: 10px auto;
  }

  .ticketsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}