// Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cardEvento {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 20px 30px;
  box-shadow: 3px 3px 20px rgba(51, 51, 51, 0.356);
  border-radius: 15px;
  padding: 10px;
  max-width: 420px;
  background-color: white;
  font-family: $tipografia;

  .contDatosEvento {
    display: flex;
    flex-direction: row;
    width: 100%;

    .contImgEvento {
      img {
        min-width: 150px;
        max-width: 150px;
        height: 150px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      @media(min-width: 768px) {
        img {
          height: 200px;
          min-width: 200px;
        }
      }
    }

    .detallesEvento {
      padding: 0 8px;
      flex-grow: 1;

      h2 {
        font-size: 16px;
        font-weight: 800;
        color: $colorDeFuente;
        margin-bottom: 4px;
      }

      .caracteristica {
        font-size: 12px;
        font-weight: 700;
        color: $colorDeFuente;

        span {
          font-weight: 400;
          color: darken($colorDeFuente, 20%);
        }
      }

      @media(min-width: 768px) {
        h2{
          font-size: 18px;
        }
        .caracteristica{
          font-size: 14px;
        }
      }
    }
  }

  .contBotonesEvento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    gap: 10px;

    .contCopyableLink{
      width: 100%;

      p{
        font-size: 12px;
        font-weight: 600;
        color: $colorDeFuente; 
        padding-left: 4px; 

        @media(min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .botonVerMas{
      font-weight: 600;
      font-size: 14px;
      color: #1b1b1b;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      transition: all 0.3s linear;

      &:hover{
        transform: scale(1.05);
      }
    }
    .botonEvento {
      width: 100%;
      display: block;

      .botonConfiguracion {
        background-color: transparent;
        color: $violetaOscuro;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
        min-height: 50px;
        max-height: 50px;

        margin: 5px 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        width: 100%;
        border: 1px solid $violetaOscuro;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .contenedorBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          svg {
            height: 18px;
            margin-right: 2px;
          }

          .contIcono {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
            background: linear-gradient(to left, $violeta, lighten($violeta, 10%));
            color: white;
            height: 50px;
            border-radius: 12px 50px 50px 16px;
            transition: background-color 0.3s ease;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
            width: 90%;
            padding: 8px 4px;
          }

          &:hover {
            .contIcono {
              animation: fillButton 0.5s forwards;
            }

            span{
              font-size: 14px;
            }

          }
        }
      }
    }
  }
  @keyframes fillButton {
    from {
      width: 10%;
      //border-radius: 10px 50px 50px 10px;
    }

    to {
      width: 25%;
      //border-radius: 7px;
    }
  }

  @keyframes defillButton {
    from {
      width: 80%;
    }

    to {
      width: 0%;
      border-radius: 0;
    }
  }
}