.pagoCardSkeleton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 90%;
  max-width: 800px;
  margin: 30px auto;
  box-shadow: 1px 0 20px #bbbbbb;
  border-radius: 15px;
  background-color: #fff;
}

.tituloSkeleton,
.lineaSkeleton {
  background-color: #ececec;
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #f4f4f4 40px,
    #ececec 80px
  );
  background-size: 500px 100%;
  animation: loading 1.2s infinite linear;
}

.iconoSkeleton {
  width: 10%;
  min-height: 100px; /* Ajuste basado en el contenido */
  border-right: solid 1px rgb(154, 66, 158);
}

.contInfoSkeleton {
  width: 90%;
  padding: 10px;
}

.fondoSubSkeleton {
  padding: 10px;
  margin-bottom: 8px;
  width: 100%;
}

.tituloSkeleton {
  width: 60%;
  height: 20px;
  margin-bottom: 5px;
}

.lineaSkeleton {
  width: 90%;
  height: 20px;
  margin-bottom: 8px;
  border-radius: 5px;
}

@keyframes loading {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
