//Colores de la empresa
$violeta: #99419d;
$violetaOscuro: #862f8b;
$rosa: #c970ce;
$tipografia: "Montserrat", -apple-system, sans-serif;
$colorDeFuente: #1b1b1b;
$colorDeFuente2: #5c5c5c;

.body {
    background: linear-gradient(100deg, #E380E9 0.71%, #65126A 97.21%);
    width: 100%;
    min-height: 80vh;
    padding: 0 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header{
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 auto 0;
        padding-top: 32px;
    
        .volver{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            color: #fff;
            padding: 0 8px;
            margin: -10px 4px 0;
            font-size: 14px;
    
            @media(min-width: 768px){
                font-size: 18px;
            }
    
            path{
                width: 40px;
            }
        }
    }
}

.productosCajaContainer {
    width: 90%;
    margin: 0 auto;
    padding: 16px 0 60px;
    display: flex;
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $tipografia;

    h2 {
        color: #fff;
        font-size: 20px;
        font-weight: 800;
        line-height: 1.2;
        margin: 16px auto 8px;
        max-width: 900px;
        padding-left: 10px;
        width: 90%;
    }

    .fondoDescripcion{
        align-items: flex-start;
        background-color: hsla(0,0%,80%,.475);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 16px;
        max-width: 900px;
        padding: 10px;
        width: 90%;

        p{
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
        }
    }

    .productos {
        width: 90%;
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .producto {
        width: 90%;
        max-width: 280px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        padding: 16px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            font-size: 14px;
            color: $colorDeFuente2;
            margin-bottom: 8px;
            width: 100%;
            text-align: left;
        }

        .inputProducto {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            padding: 8px;
            margin-bottom: 12px;
            border: 1px solid #ccc;
            font-size: 14px;
        }

        .productImage {
            width: 100%;
            max-height: width;
            background-color: #f8f8f8;
            border-radius: 8px;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                max-width: 100%;
                aspect-ratio: 1 / 1 !important;
                object-fit: cover;
                border-radius: 8px;
                border: solid 1px #ccc;
            }

            .editIcon {
                position: absolute;
                top: 10px;
                display: unset;
                right: 5px;
                z-index: 10;
                filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.5));
                color: #fff;
                cursor: pointer;
                transition: all 0.2s linear;

                &:hover {
                    color: #eeeeee;
                }
            }
        }

        .eliminarBtn {
            font-size: 12px;
            border: solid 1px rgb(255, 92, 93);
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease;
            padding: 8px 16px;
            border-radius: 8px;
            background-color: rgba(0, 0, 0, 0);
            color: rgb(255, 92, 93);
            align-self: flex-end;

            &:hover {
                background-color: rgb(255, 92, 93);
                color: white;
            }
        }

        .noEditable {
            font-size: 12px;
            color: #aaa;
            text-align: center;
            margin-top: 10px;
        }
    }

    .agregarBtn {
        font-size: 14px;
        border: solid 1px #fff;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        border-radius: 8px;
        margin-top: 20px;
        background-color: rgba(0, 0, 0, 0);
        color: #fff;

        &:hover {
            background-color: $violeta;
            color: white;
        }

        svg {
            margin-left: 8px;
        }

        @media(min-width: 768px){
            height: 490px;
            width: 280px;
            font-size: 16px;
            padding: 16px;
            margin: 0 16px;
        }
    }

    .submitButton {
        background-color: #252525;
        color: #fff;
        margin-top: 30px;
        max-width: 300px;
    }
}